import React from "react";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import { useForm } from "react-hook-form";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from "../../../assets/images/close";
import Button from "../../../components/buttons/Button";
import { createNewGroup } from "../../../redux/slices/orgSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const AddGroupsModal = ({ open, close, orgId }) => {
  const {t}=useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const addGroup = (data) => {
    data.orgId = orgId;
    dispatch(
      createNewGroup({ url: "crm/user/groups", data: data, params: {} })
    );
    close();
  };
  return (
    <BasicModal
      isOpen={open}
      onClose={close}
      boxStyle={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "42%",
        bgcolor: "background.paper",
        boxShadow: 24,
        height: "600px",
        display: "flex",
        flexDirection: "column",
        '@media (max-width: 768px)': { // Adjust the max-width as needed
          width: "80%", // Width for mobile devices
        },
      }}
      sx={modalStyle}
    >
      <>
        <div className="modal_header">
          <p className="modal_title ">{t("Add Group")}</p>
          <div className="modal_header_button_wrapper">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal_body">
          <div
            className="organisation"
            style={{ display: "block", padding: "0px" }}
          >
            <div className="">
              <form noValidate onSubmit={handleSubmit(addGroup)}>
                <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label className="text_medium">{t("Group Name")}</label>
                    <input
                      type="text"
                      name="name"
                      {...register("name", {
                        required: true,
                      })}
                    />
                    <p className="error_message">{errors.name?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label className="text_medium">{t("Description")}</label>
                    <textarea
                      type="text"
                      name="description"
                      rows={8}
                      {...register("description", {})}
                    />
                    <p className="error_message">
                      {errors.description?.message}
                    </p>
                  </Grid>
                </Grid>
                <Button type="submit" className="primary_button marginT10">
                  {t("Save")}
                </Button>
              </form>
            </div>
          </div>
        </div>
        {/* </form> */}
      </>
    </BasicModal>
  );
};

export default AddGroupsModal;
