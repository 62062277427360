import { Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/images/plus";
import Button from "../../../components/buttons/Button";
import DataTable from "../../../components/table/DataTable";
import ToolBar from "../../../components/toolbar/ToolBar";
import {
  getGroupList,
  getRolesListOrg,
  getUserListOrg,
} from "../../../redux/slices/orgSlice";
import { CardItem } from "../../../utils/CardItem";
import AddRolesModal from "./AddRolesModal";
import AssignRolesModal from "./AssignRolesModal";
import { useTranslation } from "react-i18next";
const RolesList = () => {
  const {t}=useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openAddRolesModal, setOpenAddRolesModal] = useState(false);
  const [showAssignRolesModal, setShowAssignRolesModal] = useState(false);
  const userList = useSelector((state) => state.org.userListOrg);
  const group = useSelector((state) => state.org.group);
  const rolesListOrg = useSelector((state) => state.org.rolesListOrg);
  const [selectedRole, setSelectedRole] = useState(null);
  const createData = useSelector((state) => state.form.createData);
  const roleColumns = [
    {
      field: "slno",
      headerName: t("Sl No."),
      width: 80,
    },
    {
      field: "rolId",
      headerName: t("Role Id"),
      width: 80,
    },
    {
      field: "name",
      headerName: t("Role Name"),
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },

    {
      field: "description",
      headerName: t("Description"),
      width: 300,
      renderCell: (params) => (
        <Typography>{params?.row?.description}</Typography>
      ),
    },
    {
      width: 300,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={t("Assign Role")} placement="top-start">
              <div>
                <FaUserEdit
                  style={{ fontSize: "16px", color: "#75787d" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log("params", params);
                    setSelectedRole(params.row);
                    setShowAssignRolesModal(true);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const loadGroups = () => {
    dispatch(
      getGroupList({
        url: `crm/user/groups/organization/${id}`,
        params: {},
      })
    );
  };
  const loadUsers = () => {
    dispatch(
      getUserListOrg({
        url: `/crm/user/organization/${id}/users`,
      })
    );
  };
  const loadRolesList = () => {
    dispatch(
      getRolesListOrg({
        url: `/crm/org/${id}/roles`,
      })
    );
  };
  useEffect(() => {
    loadGroups();
  }, []);
  useEffect(() => {
    loadUsers();
  }, []);
  useEffect(() => {
    loadRolesList();
  }, [createData]);
  return (
    <>
      <CardItem height="890px">
        {
          <div className="card_container">
            <div style={{ padding: "20px" }}>
              <ToolBar title={"Roles"}>
                <div className="d_flex align_items_center justify_content_between">
                  <Button
                    type="submit"
                    className="primary_button d_flex align_items_center"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "18px",
                      padding: "5px 20px",
                    }}
                    leftIcon={<PlusIcon color="#fff" />}
                    onClick={() => setOpenAddRolesModal(true)}
                  >
                    {t("Add Role")}
                  </Button>
                </div>
              </ToolBar>
            </div>

            <div style={{ padding: "20px" }}>
              <DataTable
                rows={
                  Array.isArray(rolesListOrg)
                    ? [
                        ...rolesListOrg?.map((item, index) => {
                          return {
                            ...item,
                            id: item.rolId,
                            name: item.rolName,
                            description: item.rolDesc,
                            slno: index + 1,
                          };
                        }),
                      ]
                    : []
                }
                columns={roleColumns}
                onRowClick={(row, e) => {

                  // navigate(`/org/${id}/roles/${row?.id}/assign_roles`,{
                  //   state:{
                  //     role:row.row
                  //   }
                  // })
                  // navigate(`/org/${id}/roles/${row?.id}/assign_role3`,{
                  //   state:{
                  //     role:row.row
                  //   }
                  // })
                  navigate(`/org/${id}/roles/${row?.id}/assigned_users`, {
                    state: {
                      roleName: row.row.name,
                      roleId: row.id,
                    },
                  });
                }}
              />
            </div>
          </div>
        }

        <AddRolesModal
          open={openAddRolesModal}
          close={() => setOpenAddRolesModal(false)}
          orgId={id}
        />
        <AssignRolesModal
          RoleName={selectedRole ? selectedRole.name : "Role"}
          open={showAssignRolesModal}
          close={() => setShowAssignRolesModal(false)}
          orgId={id}
          users={userList}
          groups={group}
          roleId={selectedRole?.id}
        />
      </CardItem>
    </>
  );
};

export default RolesList;
