import { useState } from "react";
import CardTitle from "./CardTitle";
import { Pie, PieChart, Cell } from "recharts";

const ConversionProjectStatistics = () => {
  const data02 = [
    {
      name: "Project Created",
      value: 2400,
    },
    {
      name: "Project Converted",
      value: 4567,
    },
    {
      name: "Project Deal",
      value: 1398,
    },
    {
      name: "Project Won",
      value: 9800,
    },
  ];

  const COLORS = ["#5B93FF", "#FFD66B", "#9BFA89", "#FF8F6B"];

  return (
    <div className="card_container">
      <CardTitle title="Projects Conversion Statistics" />
      <div
        className="card_content"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexDirection: "row",
        }}
      >
        {/* Legend */}
        <div style={{ marginRight: "20px" }}>
          {data02.map((entry, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: COLORS[index % COLORS.length],
                  marginRight: "8px",
                }}
              ></div>
              <span style={{ fontSize: "14px", color: "#333" }}>
                {entry.name}
              </span>
            </div>
          ))}
        </div>

        {/* Pie Chart */}
        <PieChart width={300} height={200}>
          <Pie
            data={data02}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={90}
            cornerRadius={50}
            paddingAngle={5}
            fill="#82ca9d"
          >
            {data02.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
};

export default ConversionProjectStatistics;
