import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export const getDateRange = (filterValue) => {
  const today = dayjs();
  let dateRange = {};

  // switch (filterValue.basedby) {
  //   case "year":
  if (filterValue.name === "This Year") {
    dateRange = {
      from: dayjs().startOf("year").format("YYYY-MM-DD"),
      to: dayjs().endOf("year").format("YYYY-MM-DD"),
    };
  } else if (filterValue.name === "Previous Year") {
    dateRange = {
      from: dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
      to: dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
    };
  }
  //   break;

  // case "month":
  if (filterValue.name === "This Month") {
    dateRange = {
      from: dayjs().startOf("month").format("YYYY-MM-DD"),
      to: dayjs().endOf("month").format("YYYY-MM-DD"),
    };
  } else if (filterValue.name === "Last Month") {
    dateRange = {
      from: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
      to: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
    };
  }
  //   break;

  // case "week":
  if (filterValue.name === "This Week") {
    dateRange = {
      from: dayjs().startOf("week").format("YYYY-MM-DD"),
      to: dayjs().endOf("week").format("YYYY-MM-DD"),
    };
  } else if (filterValue.name === "Last Week") {
    dateRange = {
      from: dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
      to: dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
    };
  }
  //   break;

  // case "date":
  if (filterValue.name === "Today") {
    dateRange = {
      from: today.format("YYYY-MM-DD"),
      to: today.format("YYYY-MM-DD"),
    };
  } else if (filterValue.name === "Yesterday") {
    dateRange = {
      from: today.subtract(1, "day").format("YYYY-MM-DD"),
      to: today.subtract(1, "day").format("YYYY-MM-DD"),
    };
  }
  //   break;

  // default:
  //   break;
  // }

  return dateRange;
};
