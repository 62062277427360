export const isObject = (value) => value !== null && typeof value === "object";
export const isFunction = (value) => typeof value === "function";
export const isEmpty = (value) =>
  value === null || value === "" || typeof value === "undefined";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  const nameParts = name?.split(" ").filter(Boolean) || [];
  return {
    sx: { bgcolor: stringToColor(name) },
    children: `${nameParts[0]?.[0]?.toUpperCase() || ""}${
      nameParts[1]?.[0]?.toUpperCase() || ""
    }`,
  };
}

export function dirtyValues(dirtyFields, allValues) {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
}

// export function dirtyValues(dirtyFields, allValues) {
//   // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
//   // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
//   if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
//   // Here, we have an object
//   return Object?.entries(
//     Object?.keys(dirtyFields)?.map((key) => [
//       key,
//       dirtyValues(dirtyFields[key], allValues[key]),
//     ])
//   );
// }
