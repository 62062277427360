import React, { useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import ToolBar from "../../../components/toolbar/ToolBar";
import CustomSearch from "../../../components/searchbar/CustomSearch";
import DataTable from "../../../components/table/DataTable";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../../components/loading/Loading";
import { useTranslation } from "react-i18next";

const Moderators = () => {
  const {t}=useTranslation();
  const teamColumns = [
    {
      field: "slno",
      headerName: t("Sl No."),
      width: 80,
    },
    {
      field: "id",
      headerName: t("User Id"),
      width: 80,
    },
    {
      field: "name",
      headerName: t("Name"),
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },
    {
      field: "email",
      headerName: t("Email"),
      width: 300,
      renderCell: (params) => {
        return <Typography>{params?.row?.email}</Typography>;
      },
    },
    {
      field: "user_type",
      headerName: t("User Type"),
      width: 300,
      renderCell: (params) => {
        let userType = "User";
        if (params.row.isOwner === "true") userType = "Owner";
        else if (params?.row?.isModerator == "true") userType = "Moderator";
        return <Typography>{userType}</Typography>;
      },
    },
  ];
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState(null);
  const dispatch = useDispatch();
  const moderatorList = useSelector((state) => state.org.moderatorsListOrg);
  const userListLoading = useSelector((state) => state.org.userListLoading);
  return (
    <>
      <CardItem height="890px">
        {userListLoading ? (
          <Loading />
        ) : (
          <div className="card_container">
            {/* <CardTitle title={selectedLink} type={"project_view"}></CardTitle> */}
            <div style={{ padding: "20px" }}>
              <ToolBar title={t("Moderators")}>
                <div className="d_flex align_items_center justify_content_between">
                  <CustomSearch
                    placeholder={t("Search")}
                    onChange={(e) => {
                      let value = e.target.value.trim();
                      if (value == "") {
                        setSearchValue(null);
                      } else {
                        setSearchValue(e.target.value);
                      }
                    }}
                  />
                </div>
              </ToolBar>
            </div>

            <div style={{ padding: "20px" }}>
              <DataTable
                rows={
                  searchValue
                    ? moderatorList.filter(
                        (item) =>
                          item.name
                            .toLowerCase()
                            .includes(searchValue?.toLowerCase()) ||
                          item.email
                            .toLowerCase()
                            .includes(searchValue?.toLowerCase())
                      ).map((item,index)=>{
                        return {
                          ...item,
                          slno:index+1
                        }
                      })
                    : [...moderatorList].map((item,index)=>{
                      return {
                        ...item,
                      slno:index+1
                      }
                    })
                }
                columns={teamColumns}
              />
            </div>
          </div>
        )}
      </CardItem>
    </>
  );
};

export default Moderators;
