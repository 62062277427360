import { useForm, useFieldArray } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, makeStyles } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  createNewMaterials,
  getMaterialType,
} from "../../../redux/slices/materialSlice";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import Storage from "../../../utils/Storage";
import { useTranslation } from "react-i18next";

const MaterialForm = ({ modalView, onClose, orgId, data, type }) => {
  const { t } = useTranslation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const materialType = useSelector((state) => state.material.materialType);
  const [openType, setOpenType] = useState(false);
  const matType = materialType.filter((item) => item.name === type);

  const [typeSelected, setTypeSelected] = useState(matType[0] ?? null);
  const loading = useSelector((state) => state.material.loading);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      materials: [
        {
          name: "",
          base_price: 0,
          description: "",
          baseuom: "",
          //   addSource: newData?.addSource ?? "",
        },
      ],
    },
  });

  //   -----------------------------
  const { fields, append, prepend, remove } = useFieldArray({
    name: "materials",
    control,
  });

  // --------------------------------

  const onSubmit = (data) => {
    const newData = {
      materials: data.materials.map((item) => ({ ...item, isactive: 1 })),
    };

    dispatch(
      createNewMaterials({
        url: `/crm/org/${orgId ?? id}/bom/store/type/${typeSelected?.matType}`,
        data: newData,
        params: {},
      })
    );
  };

  const onErrors = (errors) => {
    console.log("errors=---->", errors);
  };

  useEffect(() => {
    if (snackbarData?.createSuccess) {
      reset();

      if (modalView) {
        onClose();
      } else {
        navigate(`/org/${id}/materials/manage`);
      }
    }
  }, [snackbarData]);

  useEffect(() => {
    if (openType) {
      dispatch(
        getMaterialType({
          url: `/crm/org/${orgId ?? id}/materials`,
          params: {},
        })
      );
    }
  }, [openType]);

  return (
    <div className="organisation">
      <div className={`organisation_container ${modalView && "w_100"}`}>
        <form noValidate onSubmit={handleSubmit(onSubmit, onErrors)}>
          <div className="d_flex align_items_center justify_content_between marginB10">
            <div className="d_flex align_items_center">
              <IconButton
                onClick={modalView ? () => onClose() : () => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <h4>{t("New Material")}</h4>
            </div>
            <div className="material_type">
              <AutoCompleteAsync
                className="input_field_style"
                sx={{
                  width: "175px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                name="materialType"
                label="Type"
                open={openType}
                onOpen={() => {
                  setOpenType(true);
                }}
                onClose={() => {
                  setOpenType(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value.id
                }
                loading={loading}
                options={materialType}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setTypeSelected(null);
                  } else {
                    setTypeSelected(newValue);
                  }
                }}
                // register={register}
                renderOption={(props, option) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box key={key} {...otherProps} style={{ fontSize: 13 }}>
                      {option.name}
                    </Box>
                  );
                }}
                value={typeSelected}
                getOptionLabel={(option) => {
                  if (option.name) {
                    return option?.name;
                  } else {
                    return t("Please Select");
                  }
                }}
                // variant="standard"
                fieldSize="small"
                disabled={type ? true : false}
              />
            </div>
          </div>

          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <div
                // key={field.id}
                className="d_flex justify_content_between"
                // style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <div style={{ width: "45%" }}>
                  <label className="text_medium">{t("Material Name")}</label>

                  <input
                    type="text"
                    name="name"
                    {...register(`materials.${index}.name`, {
                      required: true,
                    })}
                  />
                  <p className="error_message">{errors.name?.message}</p>
                </div>
                <div style={{ width: "45%" }}>
                  <label className="text_medium">{t("Base Price")}</label>

                  <input
                    type="text"
                    name="base_price"
                    {...register(`materials.${index}.base_price`, {
                      required: true,
                    })}
                  />
                  <p className="error_message">{errors.base_price?.message}</p>
                </div>
              </div>
              <div
                key={field.id}
                className="d_flex justify_content_between"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <div style={{ width: "45%" }}>
                  <label className="text_medium">{t("Unit")}</label>

                  <input
                    type="text"
                    name="baseuom"
                    {...register(`materials.${index}.baseuom`, {
                      required: true,
                    })}
                  />
                  <p className="error_message">{errors.baseuom?.message}</p>
                </div>
                <div style={{ width: "45%" }}>
                  <label className="text_medium">{t("Description")}</label>
                  <textarea
                    type="text"
                    name="description"
                    rows={1}
                    {...register(`materials.${index}.description`, {
                      //   required: true,
                    })}
                  />
                  <p className="error_message">{errors.description?.message}</p>
                </div>
              </div>
              <IconButton
                sx={{ backgroundColor: "transparent !important" }}
                // className={classes.customHoverFocus}
                disabled={fields.length === 1 ? true : false}
                aria-label="delete"
                onClick={() => remove(index)}
              >
                <DeleteIcon />
              </IconButton>
              <hr />
            </React.Fragment>
          ))}
          <div className="d_flex justify_content_end">
            <IconButton
              aria-label="adding"
              onClick={(e) => {
                e.preventDefault();
                append({
                  name: "",
                  description: "",
                });
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
          <div
            className="d_flex justify_content_between"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            {/* <Button type="submit" className="primary_button marginT10">
          Save
        </Button> */}
            <Button type="submit" className="primary_button marginT10">
              {t("Save")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MaterialForm;
