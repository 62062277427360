import { useState, useRef, useEffect } from "react";
import {
  KnockProvider,
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
} from "@knocklabs/react";

// Required CSS import, unless you're overriding the styling
import "@knocklabs/react/dist/index.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Storage from "../../utils/Storage";
import { useDispatch } from "react-redux";
import { switchOrg } from "../../redux/slices/authSlice";
import { getOrganisationDetails } from "../../redux/slices/orgSlice";

const KnockNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const navigate = useNavigate();
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;
  const dispatch = useDispatch();
  const [notiOrgId, setNotiOrgId] = useState(null);
  const [notiProjectId, setNotiProjectId] = useState(null);
  const organisationDetails = useSelector(
    (state) => state.org.organisationDetails
  );

  useEffect(() => {
    if (notiProjectId) {
      if (notiOrgId && notiOrgId !== orgId) {
        dispatch(
          getOrganisationDetails({
            url: `/crm/user/organization/${notiOrgId}`,
            params: {
              // orgId: id
            },
          })
        );
      } else {
        navigate(`/projects/${notiProjectId}`);
        setIsVisible(false);
      }
    }
  }, [notiOrgId, notiProjectId]);

  useEffect(() => {
    console.log("organisationDetails=------->", organisationDetails);
    if (notiProjectId && organisationDetails) {
      dispatch(switchOrg(organisationDetails));
      navigate(`/projects/${notiProjectId}`);
      setIsVisible(false);
    }
  }, [organisationDetails]);

  useEffect(() => {
    if (!isVisible) {
      setNotiOrgId(null);
      setNotiProjectId(null);
    }
  }, [isVisible]);

  return (
    <KnockProvider
      apiKey={process.env.REACT_APP_KNOCK_PUBLIC_API_KEY}
      userId={userDetails?.uid}
      // In production, you must pass a signed userToken
      // and enable enhanced security mode in your Knock dashboard
      // userToken={currentUser.knockUserToken}
    >
      <KnockFeedProvider feedId={process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID}>
        <>
          <NotificationIconButton
            ref={notifButtonRef}
            onClick={(e) => setIsVisible(!isVisible)}
          />
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
            onNotificationClick={(item) => {
              if (orgId !== item?.data?.orgId) {
                setNotiOrgId(item?.data?.orgId);
              } else {
                setNotiOrgId(orgId);
              }
              if (item?.data?.projectId) {
                setNotiProjectId(item?.data?.projectId);
              }
            }}
          />
        </>
      </KnockFeedProvider>
    </KnockProvider>
  );
};

export default KnockNotification;
