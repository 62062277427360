import { Autocomplete, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomCheckBox from "../../../components/customCheckBox/CustomCheckBox";
import DataTable from "../../../components/table/DataTable";
import ToolBar from "../../../components/toolbar/ToolBar";

import Button from "../../../components/buttons/Button";
import Loading from "../../../components/loading/Loading";
import { PERMISSION_CONSTANTS } from "../../../constants/permissionConstants";
import { getModuleList } from "../../../redux/slices/moduleSlice";
import { getRolesListOrg } from "../../../redux/slices/orgSlice";
import {
  createPermission,
  getPermissions,
} from "../../../redux/slices/permissionSlice";
import { CardItem } from "../../../utils/CardItem";
import { useTranslation } from "react-i18next";

const Permissions = () => {
  const {t}=useTranslation();
  const { id } = useParams();
  const rolesListOrg = useSelector((state) => state.org.rolesListOrg);
  const createData = useSelector((state) => state.form.createData);
  const moduleList = useSelector((state) => state.module.moduleList);
  const permissionsData = useSelector(
    (state) => state.permissions.permissionsData
  );
  const loadingPermissions = useSelector(
    (state) => state.permissions.loadingPermissions
  );
  const rolesLoading = useSelector((state) => state.org.rolesLoading);
  const modulesLoading = useSelector((state) => state.module.modulesLoading);
  const [modulePermissionData, setModulePermissionData] = useState({});
  let rolePermissoinData = [...permissionsData?.map((item)=>{
    return {
      ...item,
      permissions:item.permissions?JSON.parse(item.permissions):null
    }
  })];
  const [selectedRole, setSelectedRole] = useState(
    rolesListOrg.length > 0 ? rolesListOrg[0] : null
  );
  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 80,
    },
    {
      field: "module",
      headerName: "Module ",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.menuName}</Typography>;
      },
    },
  ];
  const columns2 = [
    ...columns,
    ...PERMISSION_CONSTANTS.map((permissionConstant) => {
      return {
        field: permissionConstant,
        headerName: permissionConstant,
        width: 150,
        renderCell: (params) => {
         let savedPermission= permissionsData?.filter((permissionData)=>{
           return params.row.menuId === permissionData.menuid
          })[0]?.permissions;

          return (
            <>
              <div>
                <CustomCheckBox
                  checked={savedPermission? JSON.parse(savedPermission)[params.field]==1?true:false:false}
                  onChange={(e) => {
                    let rowToEdit = null;
                    let indexToEdit = null;
                    rolePermissoinData?.map((data, index) => {
                      if (data.menuid == params.row.menuId) {
                        rowToEdit = data;
                        indexToEdit = index;
                      }
                    });
                    if (rowToEdit) {
                      let currentPermissions = rowToEdit.permissions;
                      let editedPermission = {
                        ...currentPermissions,
                        [params.field]: e.target.checked ? 1 : 0,
                      };
                      let updatedRow = {
                        ...rowToEdit,
                        permissions: editedPermission,
                      };
                      rolePermissoinData.splice(indexToEdit, 1, updatedRow);
                    } else {               
                        let permissionObj = {
                          menuid: params.row.menuId,
                          permissions: {
                            [params.field]: e.target.checked ? 1 : 0,
                          },
                        };                    
                        rolePermissoinData.push(permissionObj);
                      //}
        
                    }
                  }}
                />
              </div>
            </>
          );
        },
      };
    }),
  ];

  const dispatch = useDispatch();
  const savePermission = () => {
    dispatch(
      createPermission({
        url: `/crm/org/${id}/roles/${selectedRole?.rolId}/map`,
        data: {
          menus: rolePermissoinData,
        },
      })
    );
  };
  const loadRolesList = () => {
    dispatch(
      getRolesListOrg({
        url: `/crm/org/${id}/roles`,
      })
    );
  };
  const loadModuleList = () => {
    dispatch(
      getModuleList({
        url: `/crm/common/module`,
      })
    );
  };

  const loadPermissionsData = () => {
    dispatch(
      getPermissions({
        url: `/crm/org/${id}/roles/${selectedRole?.rolId}/map`,
      })
    );
  };
  useEffect(() => {
  
    if (!rolesLoading && selectedRole) {
      loadPermissionsData();
    }
  }, [selectedRole, rolesLoading,createData]);
  useEffect(() => {
    loadRolesList();
    loadModuleList();
  }, [createData]);

  return (
    <>
      <CardItem height="890px">
        <div className="card_container">
          {(loadingPermissions===true || rolesLoading===true || modulesLoading===true) ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              <div style={{ padding: "20px" }}>
                <ToolBar title={t("Permissions")}>
                  <div className="d_flex align_items_center justify_content_between">
                    <Autocomplete
                      defaultValue={
                        rolesListOrg.length > 0 ? rolesListOrg[0].rolName : ""
                      }
                      value={selectedRole?.rolName}
                      options={
                        Array.isArray(rolesListOrg)
                          ? [
                              ...rolesListOrg.map((item, index) => {
                                return {
                                  ...item,
                                  label: item.rolName,
                                  id: item.rolId,
                                };
                              }),
                            ]
                          : []
                      }
                      onChange={(e, value) => {
                        setSelectedRole(value);
                      }}
                      sx={{ width: 250 }}
                      renderInput={(params) => {
                        return (
                          <>
                            <TextField {...params} label={t("Role")} />
                          </>
                        );
                      }}
                    />
                  </div>
                </ToolBar>
              </div>

              <div style={{ padding: "20px" }}>
                <DataTable
                  rows={[
                    ...moduleList.map((item, index) => {
                      return {
                        ...item,
                        slno: index + 1,
                        id: item.menuId,
                      };
                    }),
                  ]}
                  columns={columns2}
                />
              </div>
              <div style={{ padding: "20px" }}>
                <>
                  <Button
                    className="primary_button marginT10"
                    onClick={savePermission}
                  >
                    {t("Save Permission")}
                  </Button>
                </>
              </div>
            </>
          )}
        </div>
      </CardItem>
    </>
  );
};

export default Permissions;
