import { useTranslation } from "react-i18next";
import Button from "../../buttons/Button";
import CurrencyView from "../../currency/CurrencyView";
import PhoneNumberView from "../../PhoneNumber/PhoneNumberView";

const LabelValue = ({
  label,
  value,
  buttonLabel,
  onClick,
  className,
  type,
}) => {
  const {t}=useTranslation();
  return (
    <p className={className ?? ""}>
      <b>{label}: </b>
      <span>
        {value && value !== "" ? (
          type === "phone" ? (
            <PhoneNumberView phoneNumber={value} />
          ) : type === "currency" ? (
            <CurrencyView amount={value} />
          ) : (
            value
          )
        ) : (
          <Button
            onClick={onClick}
            className="default_button link_blue border_none p_0"
          >
            {buttonLabel}
          </Button>
        )}
      </span>
    </p>
  );
};

export default LabelValue;
