import { useState } from "react";
import CardTitle from "./CardTitle";
import { Pie, PieChart, Cell } from "recharts";
import { BorderLinearProgress } from "../constants/Constants";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

const DeliveryProjectStatistics = () => {
  const data02 = [
    {
      name: "On Time",
      value: "100",
      color: "#20B799",
    },
    {
      name: "Early(1 - 5) Days",
      value: "80",
      color: "#5B93FF",
    },
    {
      name: "Very Early(6) Days",
      value: "50",
      color: "#9BFA89",
    },
    {
      name: "Late",
      value: "30",
      color: "#F97316",
    },
  ];

  return (
    <div className="card_container">
      <CardTitle title="Projects Delivery Statistics" />
      <div className="card_content">
        <Grid2 sx={{ marginTop: "16px" }} container spacing={2}>
          {data02.map((item, index) => (
            <>
              <Grid2 item xs={12} sm={12} md={4} lg={4}>
                <Typography className="font_12">{item?.name}</Typography>
              </Grid2>
              <Grid2 item xs={12} sm={12} md={8} lg={8}>
                <BorderLinearProgress
                  height={30}
                  value={Number(item?.value)}
                  backgroundColor="transparent"
                  variant="determinate"
                  progressColor={item?.color}
                />
              </Grid2>
            </>
          ))}
        </Grid2>
      </div>
    </div>
  );
};

export default DeliveryProjectStatistics;
