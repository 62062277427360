import { Box, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CloseIcon from "../../../assets/images/close";
import Button from "../../../components/buttons/Button";
import { modalStyle } from "../../../components/constants/ConstData";
import BasicModal from "../../../components/modal/Modal";
import CustomAutoComplete from "../../../fields/autoComplete/CustomAutoComplete";
import { assignRolesToUsersAndGroups } from "../../../redux/slices/orgSlice";
import { useTranslation } from "react-i18next";
const AssignRolesModal = ({ open, close, groups, users, RoleName, roleId }) => {
  const {t}=useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log("roleId", roleId);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const [openGroupsDropDown, setOpenGroupsDropDown] = useState(false);
  const [openUsersDropDown, setOpenUsersDropDown] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [groupsSelected, setGroupsSelected] = useState([]);
  const createData = useSelector((state) => state.form.createData);
  const assignRole = (data) => {
    if (groupsSelected.length === 0 && usersSelected.length === 0) {
      close();
    } else {
      dispatch(
        assignRolesToUsersAndGroups({
          url: `/crm/org/${id}/roles/${roleId}/users`,
          data: [
            {
              role: {
                id: roleId,
                name: RoleName,
              },
              groups: [
                ...groupsSelected?.map((item) => {
                  return {
                    id: item.id,
                    isActive: 1,
                  };
                }),
              ],
              users: [
                ...usersSelected?.map((item) => {
                  return {
                    id: item.userId,
                    isActive: 1,
                  };
                }),
              ],
            },
          ],
        })
      );
    }
  };
  useEffect(()=>{
    if (createData?.createSuccess) {
      close();
      reset();  
    }
  },[createData])
  return (
    <BasicModal
      isOpen={open}
      onClose={close}
      boxStyle={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "42%",
        bgcolor: "background.paper",
        boxShadow: 24,
        height: "400px",
        display: "flex",
        flexDirection: "column",
        "@media (max-width: 768px)": {
          // Adjust the max-width as needed
          width: "80%", // Width for mobile devices
        },
      }}
      sx={modalStyle}
    >
      <>
        <div className="modal_header">
          <p className="modal_title ">
            {RoleName} {">>"}{t("Assign Roles")}
          </p>
          <div className="modal_header_button_wrapper">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div className="modal_body">
          <div
            className="organisation"
            style={{ display: "block", padding: "0px" }}
          >
            <div className="">
              <form noValidate onSubmit={handleSubmit(assignRole)}>
                <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <label>Groups</label>
                    <CustomAutoComplete
                      multiple={true}
                      width={"100%"}
                      name="groupId"
                      className="user_access_level"
                      open={openGroupsDropDown}
                      onOpen={() => {
                        setOpenGroupsDropDown(true);
                      }}
                      onClose={() => {
                        setOpenGroupsDropDown(false);
                      }}
                      // isOptionEqualToValue={(option, value) =>
                      //   option?.id === value.id
                      // }
                      // loading={loading}
                      options={groups}
                      onChange={(event, newValue) => {
                        console.log("value", newValue);
                        setGroupsSelected(newValue);
                        // setUserSelected(newValue);
                        //setGroupSelected(newValue.id);
                      }}
                      register={register}
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option.name}
                        </Box>
                      )}
                      //value={groupSelected}
                      getOptionLabel={(option) => {
                        if (option.name) {
                          return option?.name;
                        } else {
                          return t("Please Select");
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <label>{t("Users")}</label>
                    <CustomAutoComplete
                      multiple={true}
                      width={"100%"}
                      name="userId"
                      className="user_access_level"
                      open={openUsersDropDown}
                      onOpen={() => {
                        setOpenUsersDropDown(true);
                      }}
                      onClose={() => {
                        setOpenUsersDropDown(false);
                      }}
                      // isOptionEqualToValue={(option, value) =>
                      //   option?.id === value.id
                      // }
                      // loading={loading}
                      options={users}
                      onChange={(event, newValue) => {
                        setUsersSelected(newValue);
                        // setUserSelected(newValue);
                        //setGroupSelected(newValue.id);
                      }}
                      register={register}
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option.name}
                        </Box>
                      )}
                      //value={groupSelected}
                      getOptionLabel={(option) => {
                        if (option.name) {
                          return option?.name;
                        } else {
                          return t("Please Select");
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Button type="submit" className="primary_button marginT10">
                  {t("Save")}
                </Button>
              </form>
            </div>
          </div>
        </div>
        {/* </form> */}
      </>
    </BasicModal>
  );
};

export default AssignRolesModal;
