import ReplyIcon from "@mui/icons-material/Reply";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Button from "../buttons/Button";
import DateView from "../date/DateView";
import { useTranslation } from "react-i18next";

const ProjectSentMail = ({ date, mailId, subject }) => {
  const {t}=useTranslation();
  return (
    <div className="project_view_mail">
      <div className="d_flex align_items_center justify_content_between">
        <p className="my_0">
          <small>
            <DateView date={date} />
          </small>
        </p>
        <p className="my_0 d_flex align_items_center">
          <ReplyIcon />

          <small>{mailId}</small>
        </p>
      </div>
      <div className="d_flex align_items_center justify_content_between">
        <p className="my_0">{t("Subject")}: {subject}</p>
        {/* <Button className="icon_button d_flex align_items_center primary_bg text_white">
          Open
          <KeyboardDoubleArrowDownIcon className="btn_icon" />
        </Button> */}
      </div>
    </div>
  );
};

export default ProjectSentMail;
