import { Autocomplete, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomDateRangePicker from "../../../components/dateRangePicker/CustomDateRangePicker";
import DataTable from "../../../components/table/DataTable";
import ToolBar from "../../../components/toolbar/ToolBar";
import {
  getMainProjectStatusListOrg,
  getProjectListOrg,
} from "../../../redux/slices/orgSlice";
import { CardItem } from "../../../utils/CardItem";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t } = useTranslation();
  const projectColumns = [
    {
      field: "slno",
      headerName: t("Sl No."),
      width: 80,
    },
    {
      field: "id",
      headerName: t("Project Id"),
      width: 80,
    },
    {
      field: "name",
      headerName: t("Project Name"),
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },
    {
      field: "address",
      headerName: t("Address"),
      width: 300,
      renderCell: (params) => {
        return <Typography>{params?.row?.address}</Typography>;
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 300,
      renderCell: (params) => {
        return <Typography>{params?.row?.finalStatus}</Typography>;
      },
    },
  ];
  const dispatch = useDispatch();
  const { id } = useParams();
  const [projectStartFrom, setProjectStartFrom] = useState("2021-01-01");
  const [projectStartEnd, setProjectStartEnd] = useState("2029-01-01");
  const [projectDueFrom, setProjectDueFrom] = useState("2021-01-01");
  const [projectDueEnd, setProjectDueEnd] = useState("2029-01-01");
  const [projectMainStatusSelected, setProjectMainStatusSelected] =
    useState(null);
  const mainprojectStatusList = useSelector(
    (state) => state.org.mainProjectStatusListOrg
  );
  const projectList = useSelector((state) => state.org.projectListOrg);
  const formatDate = (date) => {
    if (!date) return "";
    return dayjs(date).format("YYYY-MM-DD"); // Adjust format as needed
  };

  const loadProjects = () => {
    dispatch(
      getProjectListOrg({
        url: `/crm/org/${id}/proj`,
        params: {
          orgId: Number(id),
          // page: 1,
          // pageSize: 10,
          startFrom: projectStartFrom,
          startEnd: projectStartEnd,
          dueFrom: projectDueFrom,
          dueEnd: projectDueEnd,
        },
      })
    );
  };
  const loadMainProjectStatusList = () => {
    dispatch(
      getMainProjectStatusListOrg({
        url: `/crm/org/${id}/proj/mainStatus`,
        params: {
          orgId: id,
        },
      })
    );
  };
  useEffect(() => {
    loadProjects();
  }, [projectStartFrom, projectStartEnd, projectDueFrom, projectDueEnd]);
  useEffect(() => {
    loadMainProjectStatusList();
  }, []);
  return (
    <CardItem height="890px">
      <div className="card_container">
        <div style={{ padding: "20px" }}>
          <ToolBar title={t("Projects")}>
            <div className="d_flex align_items_center justify_content_between">
              <div
                className="d_flex align_items_center justify_content_between"
                style={{ flexWrap: "wrap" }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    marginRight: "10px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    // clearIcon={null}
                    id="combo-box-demo"
                    // clearOnEscape
                    options={mainprojectStatusList.map((item) => {
                      return {
                        label: item.displayname,
                        id: item.id,
                      };
                    })}
                    onChange={(e, value) => {
                      console.log("e", e);
                      console.log("value", value);
                      if (value) {
                        setProjectMainStatusSelected(value.label);
                      } else {
                        setProjectMainStatusSelected(null);
                      }
                    }}
                    sx={{ width: 250 }}
                    renderInput={(params) => {
                      return (
                        <>
                          <TextField {...params} label={t("Status")} />
                        </>
                      );
                    }}
                  />
                </div>
                <div className="marginR10">
                  <CustomDateRangePicker
                    label={t("Start Date")}
                    defaultValue={[dayjs("2021-01-01"), dayjs("2029-01-01")]}
                    onChange={(e, value) => {
                      if (e[0]) {
                        setProjectStartFrom(formatDate(e[0]));
                      }
                      if (e[1]) {
                        setProjectStartEnd(formatDate(e[1]));
                      }
                    }}
                  />
                </div>

                <CustomDateRangePicker
                  label={t("Due Date")}
                  defaultValue={[dayjs("2021-01-01"), dayjs("2029-01-01")]}
                  onChange={(e, value) => {}}
                />
              </div>
            </div>
          </ToolBar>
        </div>
        <div style={{ padding: "20px" }}>
          <DataTable
            rows={
              projectMainStatusSelected
                ? projectList
                    .filter(
                      (item) => item.finalStatus == projectMainStatusSelected
                    )
                    .map((item, index) => {
                      return {
                        ...item,
                        slno: index + 1,
                      };
                    })
                : [...projectList].map((item, index) => {
                  console.log("inside else",item,index)
                    return {
                      ...item,
                      slno: index + 1,
                    };
                  })
            }
            columns={projectColumns}
            onRowClick={(row) => {}}
          />
        </div>
      </div>
    </CardItem>
  );
};

export default Projects;
