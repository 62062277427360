import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "../../../assets/images/close";
import PlusIcon from "../../../assets/images/plus";
import Button from "../../../components/buttons/Button";
import { modalStyle } from "../../../components/constants/ConstData";
import BasicModal from "../../../components/modal/Modal";
import ToolBar from "../../../components/toolbar/ToolBar";
import { setCreateSnackbar } from "../../../redux/slices/formSlice";
import {
  createProjectStatusOrg,
  deleteProjectStatusOrg,
  getProjectStatusListOrg,
  updateProjectStatusOrder,
} from "../../../redux/slices/orgSlice";
import { CardItem } from "../../../utils/CardItem";
import AddOrganisationStatusModal from "../view/AddOrganisationStatusModal";
import StatusCard2 from "../view/StatusCard2";
import { useTranslation } from "react-i18next";

const WorkFlowAdmin = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [openAddStatusModal, setOpenAddStatusModal] = useState(false);
  const [openEditStatusModal, setOpenEditStatusModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const createData = useSelector((state) => state.form.createData);
  const projectStatusLoading = useSelector(
    (state) => state.org.projectStatusLoading
  );
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const [reload, setReload] = useState(false);
  const projectStatusList = useSelector(
    (state) => state.org.projectStatusListOrg
  );
  const [statusList, setStatusList] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const handleEdit = (stage) => {
    // let statusItemToEdit = projectStatusList[index];
    setItemToEdit(stage);
    setValue("name", stage.name);
    setValue("url", stage.url);
    setValue("displayName", stage.displayName ? stage.displayName : stage.name);
    setValue("totalDays", stage.totalDays ? stage.totalDays : null);
    setOpenEditStatusModal(true);
  };
  const handleDelete = (statusItem) => {
    // let statusItemToDelete = projectStatusList[index];

    if (Number(statusItem.count) == 0) {
      // if count is null we can delete status Number(statusItem.count) will return 0 if count is null
      let status = [
        {
          ...statusItem,
          isactive: false,
        },
      ];

      dispatch(
        deleteProjectStatusOrg({
          url: `/crm/user/organization/${id}/status`,
          params: {
            orgId: id,
          },
          data: {
            status: status,
          },
        })
      );
    } else {
      dispatch(
        setCreateSnackbar({
          message: "Cant Delete Status.Project inside",
          createSuccess: false,
          statusCode: 404,
        })
      );
    }
  };
  const editStatus = (data) => {
    let status = [
      {
        ...itemToEdit,
        isactive: true,
        name: data.name,
        url: data.url,
        displayName: data.displayName ? data.displayName : data.name,
        totalDays: Number(data.totalDays),
      },
    ];

    dispatch(
      createProjectStatusOrg({
        url: `/crm/user/organization/${id}/status`,
        params: {
          orgId: id,
        },
        data: {
          status: status,
        },
      })
    );
  };
  const loadProjectStatus = () => {
    dispatch(
      getProjectStatusListOrg({
        url: `/crm/org/${id}/proj/status`,
        params: {
          orgId: id,
        },
      })
    );
  };
  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = statusList[dragIndex];
    if (dragItem) {
      setStatusList((prevState) => {
        const coppiedStateArray = [...prevState];
        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
        // reArrangeOrder(coppiedStateArray);
        return coppiedStateArray;
      });
    }
  };
  const reArrangeOrder = (updatedStatusList) => {
    let newStatusList = updatedStatusList.map((item, index) => {
      return {
        ...item,
        name: item.name,
        displayName: item.displayName ? item.displayName : item.name,
        count: item.count,
        id: item.id,
        order: index,
        isactive: true,
        stages: item.stages,
      };
    });
    dispatch(
      updateProjectStatusOrder({
        url: `/crm/user/organization/${id}/status`,
        params: {
          orgId: id,
          // id: projectStatus.id,
        },
        data: {
          status: [...newStatusList],
        },
      })
    );
  };
  const updateStatusList = () => {
    setStatusList(projectStatusList);
  };
  useEffect(() => {
    if (createData?.createSuccess) {
      reset();
      setOpenAddStatusModal(false);
      setOpenEditStatusModal(false);
    }
    loadProjectStatus();
  }, [createData]);

  useEffect(() => {
    setStatusList([
      ...projectStatusList.map((item) => {
        return {
          ...item,
          stages: item.stages.map((stage) => {
            return {
              ...stage,
              isactive: stage.isActive,
            };
          }),
        };
      }),
    ]);
  }, [projectStatusList]);
  return (
    <>
      <CardItem height="890px">
        {
          <div className="card_container">
            <div style={{ padding: "20px" }}>
              <ToolBar title={t("Status")}>
                <div className="d_flex align_items_center justify_content_between">
                  <Button
                    type="submit"
                    className="primary_button d_flex align_items_center"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "18px",
                      padding: "5px 20px",
                    }}
                    leftIcon={<PlusIcon color="#fff" />}
                    onClick={() => {
                      reset();
                      setOpenAddStatusModal(true);
                    }}
                  >
                    {t("Add Status")}
                  </Button>
                </div>
              </ToolBar>
            </div>

            <div style={{ padding: "20px" }}>
              <>
                <>
                  <DndProvider backend={HTML5Backend}>
                    <div
                      className=""
                      style={{
                        overflowY: "scroll",
                        maxHeight: "680px",
                      }}
                    >
                      <div
                        className="d_flex  marginT10"
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {statusList?.map((item, index) => {
                          return (
                            <>
                              <StatusCard2
                                key={item.id}
                                name={item.name}
                                stage={item}
                                index={index}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                statusList={statusList}
                                reArrangeOrder={reArrangeOrder}
                                handleViewStageClick={() => {
                                  console.log("item", item);

                                  navigate(
                                    `/org/${id}/workflow/${item.id}/stages`,
                                    { state: { projectStatus: item } }
                                  );
                                }}
                                moveCardHandler={moveCardHandler}
                                handleCheckListClick={() => {
                                  console.log(
                                    `/org/${id}/workflow/${item.id}/checklist`
                                  );
                                  navigate(
                                    `/org/${id}/workflow/${item.id}/checklist`,
                                    { state: { projectStatus: item } }
                                  );
                                }}
                                // onComplete={reArrangeOrder}
                              />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </DndProvider>
                </>
                {/* )} */}

                <BasicModal
                  isOpen={openEditStatusModal}
                  onClose={() => setOpenEditStatusModal(false)}
                  boxStyle={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "42%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    height: "430px",
                    display: "flex",
                    flexDirection: "column",
                    "@media (max-width: 768px)": {
                      // Adjust the max-width as needed
                      width: "80%", // Width for mobile devices
                    },
                  }}
                  sx={modalStyle}
                >
                  <>
                    <div className="modal_header">
                      <p className="modal_title ">{t("Edit Status")}</p>
                      <div className="modal_header_button_wrapper">
                        <IconButton
                          onClick={() => setOpenEditStatusModal(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>

                    <div className="modal_body">
                      <div
                        className="organisation"
                        style={{ display: "block", padding: "0px" }}
                      >
                        <div className="">
                          <form noValidate onSubmit={handleSubmit(editStatus)}>
                            <Grid
                              sx={{ marginTop: "16px" }}
                              container
                              spacing={2}
                            >
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <label>{t("Name")}</label>
                                <input
                                  type="text"
                                  name="name"
                                  placeholder={t("Name")}
                                  {...register("name", {
                                    required: true,
                                    //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <label>{t("Display")}</label>
                                <input
                                  type="text"
                                  name="displayName"
                                  placeholder={t("Display Name")}
                                  {...register("displayName", {
                                    required: true,
                                    //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <label>Rotting Time (In Days)</label>
                                <input
                                  type="text"
                                  name="totalDays"
                                  placeholder={t("Rotting time")}
                                  {...register("totalDays", {
                                    // required: "Rotting time is required.",
                                    validate: (value) =>
                                      !isNaN(value) ||
                                      "Rotting time must be a number.",
                                    min: {
                                      value: 0,
                                      message:
                                        "Rotting time cannot be negative.",
                                    },
                                  })}
                                />
                                {errors.totalDays && (
                                  <p className="error">
                                    {errors.totalDays.message}
                                  </p>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <label>{t("Webhook Url")}</label>
                                <input
                                  type="text"
                                  name="url"
                                  placeholder={t("Webhook")}
                                  {...register(`url`, {
                                    pattern: {
                                      value:
                                        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                                      message: "Please enter a valid URL", // Custom error message
                                    },
                                  })} 
                                  // {...register("url", {
                   
                                  // })}
                                />
                                {errors.url && (
                                  <p className="error">
                                    {errors.url.message}
                                  </p>
                                )}
                              </Grid>
                            </Grid>
                            <Button
                              type="submit"
                              className="primary_button marginT10"
                            >
                              {t("Edit Status")}
                            </Button>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* </form> */}
                  </>
                </BasicModal>
              </>
            </div>
          </div>
        }

        <AddOrganisationStatusModal
          open={openAddStatusModal}
          close={() => {
            reset();
            setOpenAddStatusModal(false);
          }}
          orgId={id}
          totalStatuses={projectStatusList.length}
        />
      </CardItem>
    </>
  );
};

export default WorkFlowAdmin;
