import React from "react";

const ListViewIcon = ({onClick,style,onMouseEnter,onMouseLeave}) => {
  return (
    <>
        <div 
        style={style}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        > 
        <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 18H3C1.34575 18 0 16.6541 0 15V3C0 1.3459 1.34575 0 3 0H15C16.6542 0 18 1.3459 18 3V15C18 16.6541 16.6542 18 15 18ZM3 1.5C2.17295 1.5 1.5 2.17295 1.5 3V15C1.5 15.8271 2.17295 16.5 3 16.5H15C15.8271 16.5 16.5 15.8271 16.5 15V3C16.5 2.17295 15.8271 1.5 15 1.5H3Z"
          fill="black"
        />
        <path
          d="M5.25 6H4.5C4.08574 6 3.75 5.66426 3.75 5.25C3.75 4.83574 4.08574 4.5 4.5 4.5H5.25C5.66426 4.5 6 4.83574 6 5.25C6 5.66426 5.66426 6 5.25 6Z"
          fill="black"
        />
        <path
          d="M13.5 6H8.25C7.83574 6 7.5 5.66426 7.5 5.25C7.5 4.83574 7.83574 4.5 8.25 4.5H13.5C13.9143 4.5 14.25 4.83574 14.25 5.25C14.25 5.66426 13.9143 6 13.5 6Z"
          fill="black"
        />
        <path
          d="M5.25 9.75H4.5C4.08574 9.75 3.75 9.41426 3.75 9C3.75 8.58574 4.08574 8.25 4.5 8.25H5.25C5.66426 8.25 6 8.58574 6 9C6 9.41426 5.66426 9.75 5.25 9.75Z"
          fill="black"
        />
        <path
          d="M13.5 9.75H8.25C7.83574 9.75 7.5 9.41426 7.5 9C7.5 8.58574 7.83574 8.25 8.25 8.25H13.5C13.9143 8.25 14.25 8.58574 14.25 9C14.25 9.41426 13.9143 9.75 13.5 9.75Z"
          fill="black"
        />
        <path
          d="M5.25 13.5H4.5C4.08574 13.5 3.75 13.1643 3.75 12.75C3.75 12.3357 4.08574 12 4.5 12H5.25C5.66426 12 6 12.3357 6 12.75C6 13.1643 5.66426 13.5 5.25 13.5Z"
          fill="black"
        />
        <path
          d="M13.5 13.5H8.25C7.83574 13.5 7.5 13.1643 7.5 12.75C7.5 12.3357 7.83574 12 8.25 12H13.5C13.9143 12 14.25 12.3357 14.25 12.75C14.25 13.1643 13.9143 13.5 13.5 13.5Z"
          fill="black"
        />
      </svg>
        </div>
    </>
  );
};

export default ListViewIcon;
