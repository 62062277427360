import { Box, Grid, IconButton } from "@mui/material";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import ModalBody from "../../../modal/ModalBody";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormState,
} from "react-hook-form";
import TextInput from "../../../../fields/text/TextInput";
import React, { useEffect, useState } from "react";
import ModalFooter from "../../../modal/ModalFooter";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createAdditionalInfo,
  createFinancialInfo,
  getFinSource,
  getFinType,
} from "../../../../redux/slices/additionalInfoSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AutoCompleteAsync from "../../../../fields/autoComplete/AutoCompleteAsync";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  financedetails: yup.array().of(
    yup.object().shape({
      type: yup.string().required("Finance Type is required"),
      loanAmt: yup
        .number()
        .required("Loan Amount is required")
        .typeError("Loan Amount must be a valid number"),
    })
  ),
});

const FinancialDetailsForm = ({ onClose, financeDetails, existingFinType }) => {
  const finTypeList = useSelector((state) => state.additionalInfo.finTypeList);

  const finTypeLoading = useSelector(
    (state) => state.additionalInfo.finTypeLoading
  );
  const finSourceList = useSelector(
    (state) => state.additionalInfo.finSourceList
  );

  const finSourceLoading = useSelector(
    (state) => state.additionalInfo.finSourceLoading
  );

  const [openFinType, setOpenFinType] = useState([]);
  const [openFinSource, setOpenFinSource] = useState([]);

  const [finTypeSelected, setFinTypeSelected] = useState([]);
  const [finTypeNewList, setFinTypeNewList] = useState(finTypeList ?? []);
  const [finTypeInputValue, setFinTypeInputValue] = useState([]);

  const [finSourceSelected, setFinSourceSelected] = useState([]);
  const [finSourceNewList, setFinSourceNewList] = useState(finSourceList ?? []);
  const [finSourceInputValue, setFinSourceInputValue] = useState([]);

  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),

    defaultValues: {
      financedetails: [
        {
          type: "",
          loanAmt: null,
          baseppw: null,
          dealerFee: null,
          totalppw: null,
        },
      ],
    },
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const { fields, append, prepend, remove } = useFieldArray({
    name: "financedetails",
    control,
  });

  const onSubmit = (data) => {
    if (financeDetails?.id) {
      data.id = financeDetails?.id;
    }
    console.log("Finance=--------->", data);

    const editedData = {
      financialdtl: data?.financedetails,
    };

    dispatch(
      createFinancialInfo({
        url: `/crm/org/${orgId}/proj/${id}/info`,
        data: editedData,
        params: { data: "financialdtl" },
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  // useEffect(() => {
  //   if (financeDetails?.financeType) {
  //     setValue("financeType", financeDetails?.financeType);
  //   }
  //   if (financeDetails?.loanAmount) {
  //     setValue("loanAmount", financeDetails?.loanAmount);
  //   }
  //   if (financeDetails?.baseppw) {
  //     setValue("baseppw", financeDetails?.baseppw);
  //   }
  //   // if (financeDetails?.adderppw) {
  //   //   setValue("adderppw", financeDetails?.adderppw);
  //   // }
  //   if (financeDetails?.dealerFee) {
  //     setValue("dealerFee", financeDetails?.dealerFee);
  //   }
  //   if (financeDetails?.totalppw) {
  //     setValue("totalppw", financeDetails?.totalppw);
  //   }
  // }, [financeDetails]);

  useEffect(() => {
    if (openFinType) {
      dispatch(
        getFinType({
          url: `crm/org/${orgId}/finance-types`,
          params: {},
        })
      );
    }
  }, [openFinType]);

  useEffect(() => {
    if (openFinSource) {
      dispatch(
        getFinSource({
          url: `crm/org/${orgId}/finance-sources`,
          params: {},
        })
      );
    }
  }, [openFinSource]);

  useEffect(() => {
    if (finTypeList) {
      setFinTypeNewList(finTypeList);
    } else {
      setFinTypeNewList([]);
    }
  }, [finTypeList]);

  useEffect(() => {
    if (finSourceList) {
      setFinSourceNewList(finSourceList);
    } else {
      setFinSourceNewList([]);
    }
  }, [finSourceList]);

  return (
    <>
      <ModalHead title={t("Finance")}>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody>
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name={`financedetails.${index}.type`}
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "This field is required",
                      // pattern: {
                      //   value: /^\d{1,10}(\.\d{0,2})?$/,
                      //   message:
                      //     "Enter up to 10 digits and 2 decimal places only",
                      // },
                    }}
                    render={({ field }) => (
                      <AutoCompleteAsync
                        {...field}
                        required={true}
                        className="input_field_style"
                        name={`financedetails.${index}.type`}
                        label={t("Finance Type")}
                        open={openFinType[index]}
                        onOpen={() => {
                          const newType = openFinType.splice(index, 0, true);
                          setOpenFinType(newType);
                        }}
                        onClose={() => {
                          const newType = openFinType.splice(index, 0, false);
                          setOpenFinType(newType);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.name === value?.name
                        }
                        loading={finTypeLoading}
                        options={finTypeNewList}
                        onChange={(event, newValue, reason) => {
                          if (reason === "clear") {
                            const newType = finTypeSelected.splice(
                              index,
                              0,
                              null
                            );
                            setFinTypeSelected(newType);
                            setValue(`financedetails.${index}.type`, null);
                          } else {
                            const containsId = existingFinType?.some(
                              (item) => item.id === newValue.id
                            );
                            if (containsId) {
                              setError(`financedetails.${index}.type`, {
                                type: "manual",
                                message: "Finance Type already added",
                              });
                            } else {
                              clearErrors(`financedetails.${index}.type`);

                              const newType = finTypeSelected.splice(
                                index,
                                0,
                                newValue?.type
                              );
                              setFinTypeSelected(newType);

                              setValue(
                                `financedetails.${index}.type`,
                                newValue.type
                              );
                            }
                          }
                        }}
                        renderOption={(props, option, { index }) => {
                          return (
                            <>
                              {option?.type !== "" ? (
                                <Box style={{ fontSize: 13 }} {...props}>
                                  {option.type}
                                </Box>
                              ) : (
                                <Box>{finTypeInputValue}</Box>
                              )}
                            </>
                          );
                        }}
                        value={finTypeSelected[index]}
                        getOptionLabel={(option) => {
                          if (option?.type) {
                            return option?.type;
                          } else {
                            return t("Please Select");
                          }
                        }}
                        fieldSize="small"
                        getOptionDisabled={(option) =>
                          finTypeSelected.includes(option.id)
                        }
                        inputValue={finTypeInputValue[0]?.value}
                        onInputChange={(event, newInputValue) => {
                          const newIpValue = finTypeInputValue.splice(
                            index,
                            0,
                            {
                              value: newInputValue,
                            }
                          );
                          setFinTypeInputValue(newIpValue);

                          if (newInputValue !== "") {
                            setFinTypeNewList((prevArray) => {
                              if (prevArray?.length === 0) {
                                // If the array is empty, just add the first object
                                return [{ id: 0, type: newInputValue }];
                              } else {
                                // Otherwise, replace the value of the last object
                                const updatedArray = [...prevArray];
                                updatedArray[updatedArray.length - 1] = {
                                  ...updatedArray[updatedArray.length - 1],
                                  id: 0,
                                  type: newInputValue,
                                };
                                return updatedArray;
                              }
                            });
                          } else {
                            setFinTypeNewList(finTypeList ?? []);
                          }
                        }}
                      />
                    )}
                  />

                  {errors && errors?.financedetails?.[index]?.type?.message && (
                    <p className="error_message">
                      {errors?.financedetails?.[index]?.type?.message}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name={`financedetails.${index}.loanAmt`}
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "This field is required",
                      pattern: {
                        value: /^\d{1,10}(\.\d{0,2})?$/,
                        message:
                          "Enter up to 10 digits and 2 decimal places only",
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        type="number"
                        variant="mui"
                        name={`financedetails.${index}.loanAmt`}
                        label={t("Amount")}
                        required={true}
                        inputClass="input_field_style"
                        fieldSize="small"
                        marginBottom="0px"
                        autoFocus={false}
                        inputProps={{
                          inputMode: "decimal", // Helps mobile users
                        }}
                      />
                    )}
                  />

                  {errors &&
                    errors?.financedetails?.[index]?.loanAmt?.message && (
                      <p className="error_message">
                        {errors?.financedetails[index]?.loanAmt?.message}
                      </p>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name={`financedetails.${index}.source`}
                    control={control}
                    defaultValue={null}
                    // rules={{
                    //   required: "This field is required",
                    //   // pattern: {
                    //   //   value: /^\d{1,10}(\.\d{0,2})?$/,
                    //   //   message:
                    //   //     "Enter up to 10 digits and 2 decimal places only",
                    //   // },
                    // }}
                    render={({ field }) => (
                      <AutoCompleteAsync
                        {...field}
                        // required={true}
                        className="input_field_style"
                        name={`financedetails.${index}.source`}
                        label={t("Source")}
                        open={openFinSource[index]}
                        onOpen={() => {
                          const newSource = openFinSource.splice(
                            index,
                            0,
                            true
                          );
                          setOpenFinSource(newSource);
                        }}
                        onClose={() => {
                          const newSource = openFinSource.splice(
                            index,
                            0,
                            false
                          );
                          setOpenFinSource(newSource);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.name === value?.name
                        }
                        loading={finSourceLoading}
                        options={finSourceNewList}
                        onChange={(event, newValue, reason) => {
                          if (reason === "clear") {
                            const newType = finSourceSelected.splice(
                              index,
                              0,
                              null
                            );
                            setFinSourceSelected(newType);
                            setValue(`financedetails.${index}.source`, null);
                          } else {
                            const containsId = existingFinType?.some(
                              (item) => item.id === newValue.id
                            );
                            if (containsId) {
                              setError(`financedetails.${index}.source`, {
                                type: "manual",
                                message: "Finance Type already added",
                              });
                            } else {
                              clearErrors(`financedetails.${index}.source`);

                              const newType = finTypeSelected.splice(
                                index,
                                0,
                                newValue?.name
                              );
                              setFinSourceSelected(newType);

                              setValue(
                                `financedetails.${index}.source`,
                                newValue.name
                              );
                            }
                          }
                        }}
                        renderOption={(props, option, { index }) => {
                          return (
                            <>
                              {option?.name !== "" ? (
                                <Box style={{ fontSize: 13 }} {...props}>
                                  {option.name}
                                </Box>
                              ) : (
                                <Box>{finSourceInputValue}</Box>
                              )}
                            </>
                          );
                        }}
                        value={finSourceSelected[index]}
                        getOptionLabel={(option) => {
                          if (option?.name) {
                            return option?.name;
                          } else {
                            return t("Please Select");
                          }
                        }}
                        fieldSize="small"
                        getOptionDisabled={(option) =>
                          finSourceSelected.includes(option.id)
                        }
                        inputValue={finSourceInputValue[0]?.value}
                        onInputChange={(event, newInputValue) => {
                          const newIpValue = finSourceInputValue.splice(
                            index,
                            0,
                            {
                              value: newInputValue,
                            }
                          );
                          setFinSourceInputValue(newIpValue);

                          if (newInputValue !== "") {
                            setFinSourceNewList((prevArray) => {
                              if (prevArray?.length === 0) {
                                // If the array is empty, just add the first object
                                return [{ id: 0, type: newInputValue }];
                              } else {
                                // Otherwise, replace the value of the last object
                                const updatedArray = [...prevArray];
                                updatedArray[updatedArray.length - 1] = {
                                  ...updatedArray[updatedArray.length - 1],
                                  id: 0,
                                  name: newInputValue,
                                };
                                return updatedArray;
                              }
                            });
                          } else {
                            setFinSourceNewList(finSourceList ?? []);
                          }
                        }}
                      />
                    )}
                  />

                  {errors &&
                    errors?.financedetails?.[index]?.source?.message && (
                      <p className="error_message">
                        {errors?.financedetails?.[index]?.source?.message}
                      </p>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name={`financedetails.${index}.referenceNo`}
                    control={control}
                    defaultValue={null}
                    // rules={{
                    //   required: "This field is required",
                    //   pattern: {
                    //     value: /^\d{1,10}(\.\d{0,2})?$/,
                    //     message:
                    //       "Enter up to 10 digits and 2 decimal places only",
                    //   },
                    // }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        // type="number"
                        variant="mui"
                        name={`financedetails.${index}.referenceNo`}
                        label={t("Reference Number")}
                        // required={true}
                        inputClass="input_field_style"
                        fieldSize="small"
                        marginBottom="0px"
                        autoFocus={false}
                        // inputProps={{
                        //   inputMode: "decimal", // Helps mobile users
                        // }}
                      />
                    )}
                  />

                  {errors &&
                    errors?.financedetails?.[index]?.referenceNo?.message && (
                      <p className="error_message">
                        {errors?.financedetails[index]?.referenceNo?.message}
                      </p>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Controller
                    name={`financedetails.${index}.comments`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        id="outlined-multiline-static"
                        variant="mui"
                        name={`financedetails.${index}.comments`}
                        label={t("Comments")}
                        inputClass="input_field_style"
                        fieldSize="small"
                        marginBottom="0px"
                        autoFocus={false}
                        multiline={true}
                        rows={4}
                      />
                    )}
                  />
                  {errors &&
                    errors?.financedetails?.[index]?.comments?.message && (
                      <p className="error_message">
                        {errors?.financedetails[index]?.comments?.message}
                      </p>
                    )}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name={`financedetails.${index}.baseppw`}
                    control={control}
                    defaultValue={null}
                    rules={{
                      // required: 'This field is required',
                      pattern: {
                        value: /^\d{1,10}(\.\d{0,2})?$/,
                        message:
                          "Enter up to 10 digits and 2 decimal places only",
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        type="number"
                        variant="mui"
                        name={`financedetails.${index}.baseppw`}
                        label="Base PPW"
                        inputClass="input_field_style"
                        fieldSize="small"
                        marginBottom="0px"
                        autoFocus={false}
                        inputProps={{
                          inputMode: "decimal", // Helps mobile users
                        }}
                      />
                    )}
                  />

                  {errors &&
                    errors?.financedetails?.[index]?.baseppw?.message && (
                      <p className="error_message">
                        {errors?.financedetails[index]?.baseppw?.message}
                      </p>
                    )}
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name={`financedetails.${index}.dealerFee`}
                    control={control}
                    defaultValue={null}
                    rules={{
                      // required: 'This field is required',
                      pattern: {
                        value: /^\d{1,10}(\.\d{0,2})?$/,
                        message:
                          "Enter up to 10 digits and 2 decimal places only",
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        type="number"
                        variant="mui"
                        name={`financedetails.${index}.dealerFee`}
                        label="Dealer Fee"
                        inputClass="input_field_style"
                        fieldSize="small"
                        marginBottom="0px"
                        autoFocus={false}
                        inputProps={{
                          inputMode: "decimal", // Helps mobile users
                        }}
                      />
                    )}
                  />

                  {errors &&
                    errors?.financedetails?.[index]?.dealerFee?.message && (
                      <p className="error_message">
                        {errors?.financedetails[index]?.dealerFee?.message}
                      </p>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name={`financedetails.${index}.totalppw`}
                    control={control}
                    defaultValue={null}
                    rules={{
                      // required: 'This field is required',
                      pattern: {
                        value: /^\d{1,10}(\.\d{0,2})?$/,
                        message:
                          "Enter up to 10 digits and 2 decimal places only",
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        type="number"
                        variant="mui"
                        name={`financedetails.${index}.totalppw`}
                        label="Total PPW"
                        inputClass="input_field_style"
                        fieldSize="small"
                        marginBottom="0px"
                        autoFocus={false}
                        inputProps={{
                          inputMode: "decimal", // Helps mobile users
                        }}
                      />
                    )}
                  />
                  {errors &&
                    errors?.financedetails?.[index]?.totalppw?.message && (
                      <p className="error_message">
                        {errors?.financedetails[index]?.totalppw?.message}
                      </p>
                    )}
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="d_flex justify_content_end">
                    <IconButton
                      sx={{
                        backgroundColor: "transparent !important",
                        // marginTop: "20px",
                      }}
                      // className={classes.customHoverFocus}
                      disabled={fields.length === 1 ? true : false}
                      aria-label="delete"
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
          <div className="d_flex justify_content_end">
            <IconButton
              aria-label="adding"
              onClick={(e) => {
                e.preventDefault();
                append({
                  type: "",
                  loanAmt: null,
                  baseppw: null,
                  dealerFee: null,
                  totalppw: null,
                });
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="primary_button">
            {t("Save")}
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default FinancialDetailsForm;
