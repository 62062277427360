import React, { useEffect, useState } from "react";
import {
  createProjectStatusGroup,
  createStatusGroupUser,
  getProjectStatusList,
} from "../../../redux/slices/projectSlice";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AutoCompleteGroupNew from "../../../fields/autoComplete/AutoCompleteGroupNew";
import MovingIcon from "@mui/icons-material/Moving";
import Loading from "../../../components/loading/Loading";
import AutoCompleteDefaultAssigne from "../../../fields/autoComplete/AutoCompleteDefaultAssigne";
import Button from "../../../components/buttons/Button";
import { useTranslation } from "react-i18next";
const DefaultAssigneNew = ({ groups, users }) => {
  const {t}=useTranslation();
  const { id } = useParams();
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );
  let groupsAndUsersOfStatus = useSelector(
    (state) => state.project.groupsAndUsersOfStatus
  );

  const [groupSelected, setGroupSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const createData = useSelector((state) => state.form.createData);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [statusGroupsUserData, setStatusGroupsUserData] = useState([
    ...groupsAndUsersOfStatus,
  ]);

  const statusLoading = useSelector((state) => state.project.statusLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadStatusList = () => {
    dispatch(
      getProjectStatusList({ url: `crm/org/${id}/proj/status`, params: {} })
    );
  };

  const updateGroupsAndUsersofStatus = (data, type, statusId, reason) => {
    if (reason == "selectOption") {
      let indexToEdit = null;
      let groupsData = [];
      let usersData = [];
      let statusToEdit = null;
      groupsAndUsersOfStatus.map((item, index) => {
        if (item?.status?.id == statusId) {
          indexToEdit = index;
          statusToEdit = item.status;
          groupsData = item.groups;
          usersData = item.users;
        }
      });

      let temp = statusGroupsUserData;
      temp.splice(indexToEdit, 1, {
        status: statusToEdit,
        groups:
          type === "groups"
            ? data.map((item) => {
                return {
                  ...item,
                  isActive: 1,
                };
              })
            : groupsData,
        users:
          type === "users"
            ? data.map((item) => {
                return {
                  ...item,
                  isActive: 1,
                };
              })
            : usersData,
      });
      setStatusGroupsUserData(temp);
    } else if (reason == "removeOption") {
      let indexToEdit = null;
      let groupsData = [];
      let usersData = [];
      let statusToEdit = null;
      groupsAndUsersOfStatus.map((item, index) => {
        if (item.status.id == statusId) {
          indexToEdit = index;
          statusToEdit = item.status;
          groupsData = item.groups;
          usersData = item.users;
        }
      });
      let result = [];
      if (type == "groups") {
        result = groupsData.filter(
          (largeItem) =>
            !data.some((smallItem) => largeItem.id === smallItem.id)
        );
        data = [
          ...data,
          ...result.map((item) => {
            return {
              ...item,
              isActive: 0,
            };
          }),
        ];
      } else if (type == "users") {
        result = usersData.filter(
          (largeItem) =>
            !data.some((smallItem) => largeItem.id === smallItem.id)
        );
        data = [
          ...data,
          ...result.map((item) => {
            return {
              ...item,
              isActive: 0,
            };
          }),
        ];
      }

      let temp = statusGroupsUserData;
      temp.splice(indexToEdit, 1, {
        status: statusToEdit,
        groups: type === "groups" ? data : groupsData,
        users: type === "users" ? data : usersData,
      });
      setStatusGroupsUserData(temp);
    }
  };

  const saveUsersAndGroupsData = () => {
    dispatch(
      createStatusGroupUser({
        url: `/crm/org/${id}/status/map`,
        data: statusGroupsUserData.map((item) => {
          return {
            status: {
              id: item.status.id,
              name: item.status.name,
            },
            groups: item.groups,
            users: item.users,
          };
        }),
        params: { orgId: id },
        //params:{}
      })
    );
  };

  useEffect(() => {
    loadStatusList();
  }, [createData]);

  return (
    <>
      <div style={{ height: "650px", overflowY: "scroll" }}>
        <div className="org_list_wrapper">
          {projectStatusList.length > 0 ? (
            !statusLoading &&
            projectStatusList?.map((status) => {
              return (
                <>
                  <div key={status.id} className="light_bg_border">
                    <div className="d_flex justify_content_between w_100">
                      <div className="w_100">
                        <div className="d_flex align_items_center">
                          <MovingIcon
                            style={{ color: "#189d3d" }}
                            className="org_icon"
                          />
                          <div>
                            <h4 className="my_0">
                              {status?.displayName
                                ? status.displayName
                                : status.name}
                            </h4>
                          </div>
                        </div>
                        <div className="d_flex align_items_center  w_100">
                          <AutoCompleteDefaultAssigne
                            saveData={() => {
                              setShowSaveButton(true);
                            }}
                            saveUsersAndGroupsData={saveUsersAndGroupsData}
                            updateGroupsAndUsersofStatus={
                              updateGroupsAndUsersofStatus
                            }
                            setStatusGroupsUserData={setStatusGroupsUserData}
                            status={status}
                            alreadyPresentGroups={status.groups}
                            alreadyPresentUsers={status.users}
                            users={users}
                            groups={groups}
                            view="status"
                            name="group"
                            type="group"
                            // label="Group"
                            userLabel="Add Default Assignee"
                            onGroupChange={(event, newValue, reason) => {
                              if (reason === "clear") {
                                setGroupSelected(null);
                              }
                              const groupData = [
                                {
                                  status: {
                                    id: status?.id,
                                    name: status?.name,
                                  },
                                  groups: {
                                    id: newValue?.id,
                                    name: newValue?.name,
                                  },
                                },
                              ];
                            }}
                            onUserChange={(event, newValue, reason) => {
                              if (reason === "clear") {
                                setUserSelected(null);
                              }
                              const userData = [
                                {
                                  group: {
                                    id: status?.groupId,
                                    name: status?.groupName,
                                  },
                                  user: {
                                    id: newValue?.id,
                                    name: newValue?.name,
                                  },
                                },
                              ];
                              dispatch(
                                createStatusGroupUser({
                                  url: `/crm/org/${id}/groups/map/user`,
                                  data: userData,
                                  params: {},
                                })
                              );
                            }}
                            value={
                              status?.groupId && !status?.userId
                                ? {
                                    userId: null,
                                    name: null,
                                    groupId: status?.groupId ?? null,
                                    groupName: status?.groupName ?? null,
                                  }
                                : {
                                    userId: status?.userId ?? null,
                                    name: status?.userName ?? null,
                                    groupId: status?.groupId ?? null,
                                    groupName: status?.groupName ?? null,
                                  }
                            }
                            groupWidth="40%"
                            userWidth="40%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <Loading />
          )}
        </div>
      </div>
      {showSaveButton && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="primary_button marginT10"
            onClick={saveUsersAndGroupsData}
          >
            {t("Save")}
          </Button>
        </div>
      )}
    </>
  );
};

export default DefaultAssigneNew;
