import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import CardTitle from "./CardTitle";

// GeoJSON URL for US States
const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

// Sample data for states
const stateData = {
  California: 99,
  Texas: 500,
  "New York": 1,
  Florida: 500,
};

// Create a color scale
const getColor = (value) => {
  if (value === 0) return "#FFFFFF"; // White
  if (value >= 1 && value <= 10) return "#E3FFDE"; // Light green
  if (value >= 11 && value <= 100) return "#8BED60"; // Medium green
  if (value >= 101 && value <= 500) return "#189D3D"; // Dark green
  return "#D3D3D3"; // Default color (gray) if no data
};

const StateProjectStatistics = () => {
  const [tooltipContent, setTooltipContent] = useState("");

  return (
    <div className="card_container">
      <CardTitle title="State wise project statics"></CardTitle>
      {/* <div className="card_content w_100"> */}
      <div style={{ width: "100%", height: "100%" }}>
        <ComposableMap projection="geoAlbersUsa">
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const stateName = geo.properties.name;
                const stateValue = stateData[stateName] || 0; // Default to 0 if no data
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={getColor(stateValue)} // Apply color based on value
                    onMouseEnter={() => {
                      setTooltipContent(`${stateName}: ${stateValue}`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    style={{
                      default: {
                        outline: "none",
                        stroke: "#E8E8E8", // Border color
                        strokeWidth: 0.5, // Border thickness
                      },
                      hover: {
                        outline: "none",
                        stroke: "#000", // Border color on hover
                        strokeWidth: 1, // Thicker border on hover
                      },
                      pressed: { outline: "none" },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
        <p>{tooltipContent}</p>
      </div>
      {/* </div> */}
    </div>
  );
};

export default StateProjectStatistics;
