import { useTranslation } from "react-i18next";
import useWindowSize from "../../../utils/useWindowSize";
import EmptyList from "../../emptylist/EmptyList";
import DeveloperBoardOffIcon from "@mui/icons-material/DeveloperBoardOff";

const DescriptionCard = ({ data, onToggle, isExpanded }) => {
  const { width } = useWindowSize();
  const maxLength = width < 1200 ? 65 : 200;
  const {t}=useTranslation();
  const shouldTruncate = data?.description?.length > maxLength;
  return (
    <div className="card_container">
      <div style={{ padding: "11.37px 20px 13.13px 20px" }}>
        <div>
          <label className="text_medium_bold">{t("Description")}</label>
          {data?.description ? (
            <p>
              {shouldTruncate && !isExpanded
                ? data?.description.substring(0, maxLength) + "..."
                : data?.description}
              {shouldTruncate && (
                <span className="pointer marginL10" onClick={onToggle}>
                  <b>{isExpanded ? "Show Less" : "Show More"}</b>
                </span>
              )}
            </p>
          ) : (
            <EmptyList
              className="d_flex flex_column "
              message={t("No description added")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DescriptionCard;
