import { Paper, styled } from "@mui/material";

export const CardItem = styled(Paper)(
  ({ theme, height, showscrollbar, backgroundColor, overlayColor }) => ({
    backgroundColor: backgroundColor
      ? backgroundColor
      : theme.palette.mode === "dark"
      ? "#1A2027"
      : "#fff",
    ...theme.typography.body2,
    borderRadius: "8px",
    color: theme.palette.text.secondary,
    height: height ?? "auto",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    marginBottom: "15px",
    // overflowY: showscrollbar ?? "auto",
    WebkitScrollBar: "none",
    position: "relative",

    // Overlay using ::after pseudo-element
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: overlayColor ?? "none", // Default overlay color
      borderRadius: "8px", // Match the border radius
      pointerEvents: "none", // Allows clicks to pass through the overlay
    },
  })
);
