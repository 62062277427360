import React, { useEffect, useState } from "react";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import { Box, Grid, IconButton } from "@mui/material";
import CloseIcon from "../../../assets/images/close";
import { useForm } from "react-hook-form";
import CustomAutoComplete from "../../../fields/autoComplete/CustomAutoComplete";
import Button from "../../../components/buttons/Button";
import { getGroupList, inviteNewUser } from "../../../redux/slices/orgSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./InviteUserModal.scss";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Email address is invalid")
    .required("Email is required"),
});

const InviteUserModal = ({ open, close, orgId }) => {
  const {t}=useTranslation();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
    resolver: yupResolver(schema),
  });
  const group = useSelector((state) => state.org.group);
  const [groupSelected, setGroupSelected] = useState();
  const [openDropDown, setOpenDropDown] = useState(false);
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const dispatch = useDispatch();
  const inviteUser = (data) => {
    data.orgId = orgId;
    data.groupId = groupSelected;
    dispatch(inviteNewUser({ url: "crm/user/invite", data: data, params: {} }));
    close();
  };

  const loadGroups = () => {
    dispatch(
      getGroupList({ url: `crm/user/groups/organization/${orgId}`, params: {} })
    );
  };
  useEffect(() => {
    if (snackbarData?.createSuccess) {
      reset();
      close();
    }
    loadGroups();
  }, [snackbarData]);
  return (
    <>
      <BasicModal
        isOpen={open}
        onClose={close}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "400px",
          display: "flex",
          flexDirection: "column",
          "@media (max-width: 768px)": {
            // Adjust the max-width as needed
            width: "80%", // Width for mobile devices
          },
        }}
        // sx={modalStyle}
      >
        {/* <LeadForm
          // loadLeads={loadLeads}
          onClose={() => setOpenInviteUserModal(false)}
        /> */}
        <>
          <div className="invite_user_modal_header">
            <p className=" invite_user_modal_heading">{t("Invite Users")}</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          {/* <form
            className="project_form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          > */}
          <div className="">
            <div
              className="organisation invite_user_modal_body"
              //   style={{ display: "block", padding: "0px" }}
            >
              <div style={{ width: "100%" }}>
                <form noValidate onSubmit={handleSubmit(inviteUser)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <label>{t("Team Member")}</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        {...register("email", {
                          required: true,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                      {errors && errors?.email?.message && (
                        <p className="error_message">
                          {errors?.email?.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <label>{t("Group")}</label>
                      <CustomAutoComplete
                        width={"100%"}
                        name="groupId"
                        className="user_access_level"
                        open={openDropDown}
                        onOpen={() => {
                          setOpenDropDown(true);
                        }}
                        onClose={() => {
                          setOpenDropDown(false);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value.id
                        }
                        // loading={loading}
                        options={group}
                        onChange={(event, newValue) => {
                          setGroupSelected(newValue.id);
                        }}
                        register={register}
                        renderOption={(props, option) => (
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option.name}
                          </Box>
                        )}
                        value={groupSelected}
                        getOptionLabel={(option) => {
                          if (option.name) {
                            return option?.name;
                          } else {
                            return "Please Select";
                          }
                        }}
                        noOptionsText={
                          <div className="d_flex flex_column align_items_center justify_content_center">
                            <p>{t("No Data Found")}</p>
                            <Button
                              className="primary_button_small"
                              onClick={(e) => {
                                e.preventDefault();
                                // navigate(`/org/${orgId}/group/create`);
                              }}
                            >
                              {t("Create New")}
                            </Button>
                          </div>
                        }
                        // label="Access level"
                        // {...register("access_level", {
                        //   required: true,
                        // })}
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    {t("Send Invites")}
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </>
  );
};

export default InviteUserModal;
