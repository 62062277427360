import { takeLatest, put, fork, call } from "redux-saga/effects";
import {
  createItem,
  deleteItem,
  editItem,
  getItem,
  uploadItem,
} from "../../services/service";
import {
  createNewFolder,
  deleteFile,
  deleteFiles,
  fileUpload,
  fileUploadSuccess,
  folderBulkDownload,
  getFileList,
  // getFolderList,
  setFileList,
  setFileUploadLoadingFalse,
  // setFolderList,
  setSuccess,
  singleFileUpload,
  singleFileUploadSuccess,
  uploadFileProgress,
} from "../slices/fileUploadSlice";
import { setDialog } from "../slices/dialogSlice";
import {
  setCreateFailed,
  setCreateSnackbar,
  setCreateSuccess,
} from "../slices/formSlice";
import { setProjectLoadingFalse } from "../slices/projectSlice";
import store from "../store";

const splitFilesIntoGroups = (files, maxGroupSize) => {
  const fileGroups = [];
  let currentGroup = [];
  let currentGroupSize = 0;

  files.forEach((file) => {
    if (currentGroupSize + file.size > maxGroupSize) {
      fileGroups.push(currentGroup);
      currentGroup = [];
      currentGroupSize = 0;
    }

    currentGroup.push(file);
    currentGroupSize += file.size;
  });

  if (currentGroup.length > 0) {
    fileGroups.push(currentGroup);
  }

  return fileGroups;
};

const dispatchProgress = (progress, group) => {
  store.dispatch(uploadFileProgress({ progress, group }));
};

function* onFileUploadAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const newData = data.map((item, index) => {
      item.fileIndex = index;
      return item;
    });
    const fileGroups = splitFilesIntoGroups(newData, 500 * 1024 * 1024);

    for (let groupIndex = 0; groupIndex < fileGroups.length; groupIndex++) {
      const group = fileGroups[groupIndex];
      const formData = new FormData();
      group.forEach((file) => formData.append("files", file));

      const response = yield call(uploadItem, {
        url,
        data: formData,
        params,
        onUploadProgress: (progress) => {
          dispatchProgress(progress, group);
        },
      });

      const result = JSON.parse(response.data);
      yield put(fileUploadSuccess(result));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          message: error.errors.message || "An Error occured",
          createSuccess: false,
          statusCode: error.errors.status || 500,
        })
      );
    }
    yield put(setFileUploadLoadingFalse());
  }
}

function* onUploadFile() {
  yield takeLatest(fileUpload.type, onFileUploadAsync);
}

function* onSingleFileUploadAsync({ payload }) {
  try {
    const { url, data, params, onUploadProgress } = payload;

    const response = yield call(uploadItem, {
      url,
      data: data,
      params,
      onUploadProgress: onUploadProgress,
    });

    const result = JSON.parse(response.data);
    if (result?.message) {
      yield put(singleFileUploadSuccess(true));
      yield put(
        setCreateSnackbar({
          message: result?.message,
          createSuccess: true,
          statusCode: response?.status,
        })
      );
    }
  } catch (error) {
    if (error?.message?.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          message: error.errors.message || "An Error occured",
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setFileUploadLoadingFalse());
  }
}

function* onSingleUploadFile() {
  yield takeLatest(singleFileUpload.type, onSingleFileUploadAsync);
}

function* onFileDeleteAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(deleteItem, { url, data, params });
    // yield put(
    //   fileUploadSuccess({
    //     ...response?.data,
    //     // createSuccess: response?.status === 201 ? true : false,
    //     // statusCode: response?.status,
    //   })
    // );
  } catch (error) {
    console.log("SagaError=------->", error);
  }
}

function* onDeleteFile() {
  yield takeLatest(deleteFile.type, onFileDeleteAsync);
}

function* onCreateFolderAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setFileUploadLoadingFalse());
  }
}

function* onCreateFolder() {
  yield takeLatest(createNewFolder.type, onCreateFolderAsync);
}

function* onFolderBulkDownloadAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setFileUploadLoadingFalse());
  }
}

function* onFolderBulkDownload() {
  yield takeLatest(folderBulkDownload.type, onFolderBulkDownloadAsync);
}

// function* onLoadFolderListAsync({ payload }) {
//   try {
//     const { url, params } = payload;
//     const response = yield call(getItem, { url, params });
//     // if (response.status === 200) {
//     yield put(setFolderList([...response.data]));
//     // }
//   } catch (error) {
//     if (error.message.includes("Firebase ID token has expired.")) {
//       yield put(setDialog(true));
//     } else {
//       yield put(
//         setCreateFailed({
//           ...error?.errors?.data,
//           createSuccess: false,
//           statusCode: error.errors.status,
//         })
//       );
//     }
//     yield put(setFileUploadLoadingFalse());
//   }
// }

// function* onLoadAFolderList() {
//   yield takeLatest(getFolderList.type, onLoadFolderListAsync);
// }

function* onLoadFileListAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setFileList([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setFileUploadLoadingFalse());
  }
}

function* onLoadAFileList() {
  yield takeLatest(getFileList.type, onLoadFileListAsync);
}

function* onUpdateFileAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(setSuccess(response));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setFileUploadLoadingFalse());
  }
}

function* onUpdateFile() {
  yield takeLatest(deleteFiles.type, onUpdateFileAsync);
}

export const fileUploadSagas = [
  fork(onUploadFile),
  fork(onSingleUploadFile),
  fork(onDeleteFile),
  fork(onCreateFolder),
  // fork(onLoadAFolderList),
  fork(onLoadAFileList),
  fork(onFolderBulkDownload),
  fork(onUpdateFile),
];
