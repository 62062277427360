import { Box, Grid, IconButton } from "@mui/material";
import EyeIcon from "../../../assets/images/eye";
import LinkIcon from "../../../assets/images/link";
import ThreeDotIcon from "../../../assets/images/threedot";
import CloseIcon from "../../../assets/images/close";
import TextEditor from "../../../fields/texteditor/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PlacesAutoComplete from "../../../components/maps/PlacesAutoComplete";
import { useForm } from "react-hook-form";
// import "./style.scss";
import ContactForm from "../../../components/customer/ContactForm";
import Button from "../../../components/buttons/Button";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import DragDropUploader from "../../../components/fileupload/DragDropUploader";

import {
  createNewLead,
  editLead,
  getLeadOffice,
  getLeadSource,
} from "../../../redux/slices/leadSlice";
import AutoCompleteGroup from "../../../fields/autoComplete/AutoCompleteGroup";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Storage from "../../../utils/Storage";
import dayjs from "dayjs";
import { deleteFile, fileUpload } from "../../../redux/slices/fileUploadSlice";
import { setCreateSuccess } from "../../../redux/slices/formSlice";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  address: yup.string().required("Address is required"),
  customerInfo: yup.object().shape({
    pContact: yup.object().shape({
      firstName: yup.string().required("FirstName is required"),
      lastName: yup.string().required("LastName is required"),
      email: yup
        .string()
        .matches(
          /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
          "Enter a valid email address"
        )
        .required("Email is required"),
      mobile: yup.string().required("Mobile is required"),
    }),
    sContact: yup.object().shape({
      firstName: yup.string(),
      lastName: yup.string(),
      email: yup
        .string()
        .matches(
          /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
          "Enter a valid email address"
        ),
      mobile: yup.string(),
    }),
  }),
});

const LeadForm = ({ loadLeads, leadData, id, onClose }) => {
  const { t } = useTranslation();
  const [showSecondary, setShowSecondary] = useState(false);
  const [openSource, setOpenSource] = useState(false);
  const [openOffice, setOpenOffice] = useState(false);
  const [sourceSelected, setSourceSelected] = useState(
    leadData?.source ?? null
  );
  const [officeSelected, setOfficeSelected] = useState(
    leadData?.office ?? null
  );

  const [description, setDescription] = useState(leadData?.description ?? null);
  const [content, setContent] = useState(
    leadData?.description ? `<p>${leadData?.description}</p>` : null
  );
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;

  const leadLoading = useSelector((state) => state.lead.loading);
  const sourceList = useSelector((state) => state.lead.leadSource);
  const officeList = useSelector((state) => state.lead.leadOffice);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [setter, setSetter] = useState({});
  const [salesRep, setSalesRep] = useState({});
  const [isPurchaseOrderFile, setIsPurchaseOrderFile] = useState([]);
  const currentFile = useSelector((state) => state.upload.currentFile);
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const uploadLoading = useSelector((state) => state.upload.loading);
  const [addressDetails, setAddressDetails] = useState(null);
  const createData = useSelector((state) => state.form.createData);

  const [projectData, setProjectData] = useState({
    projectName: "",
  });

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (currentFile && currentFile?.length > 0) {
      // updatePurchaseOrder(currentFile.files, isPurchaseOrderFile);
      data.files = currentFile?.map((file, index) => {
        if (isPurchaseOrderFile.includes(file?.name)) {
          return {
            name: file?.name,
            url: file?.url,
            updated: true,
            isPurchaseOrder: true,
          };
        }

        return { name: file?.name, url: file?.url, updated: true };
      });
    } else {
      data.files = [];
    }

    data.office = {
      id: officeSelected?.id,
      name: officeSelected?.name,
    };

    data.source = {
      id: sourceSelected?.id,
      name: sourceSelected?.name,
    };

    data.setter = setter;
    data.salesRep = salesRep;

    data.description = description;

    var now = dayjs();
    const chatId = userInfo.id.toString() + dayjs(now).unix().toString();
    data.chatId = chatId;
    data.addressDetails = addressDetails;
    if (uploadLoading) {
      dispatch(
        setCreateSuccess({
          message:
            "You can save the Lead only after the file upload is completed",
          createSuccess: true,
          statusCode: 600,
        })
      );
    } else {
      if (id) {
        dispatch(
          editLead({
            url: `crm/org/${orgId}/leads/${id}`,
            data: data,
            params: {},
          })
        );
      } else {
        dispatch(
          createNewLead({
            url: `crm/org/${orgId}/leads`,
            data: data,
            params: {},
          })
        );
      }
    }
  };

  const handlePhoneNumber = (phoneNumber, type) => {
    setValue(`customerInfo.${type}.mobile`, phoneNumber);
  };

  const handleSelect = (address, addressValue) => {
    setValue("address", address);
    clearErrors("address");
    setAddressDetails(addressValue);
  };

  const handleGroup = (value, type) => {
    if (type === "setter") {
      setSetter(value);
    } else {
      setSalesRep(value);
    }
  };

  const handleDescription = (content, delta, source, editor) => {
    setContent(content);
    const descValue = editor.getText(content).replace(/\n/g, " ");

    setDescription(descValue);
  };

  const handleUploadProgresss = (value) => {
    setProgress(Math.round((100 * value.loaded) / value.total));
  };

  const handleFile = (files) => {
    if (files[0]) {
      setFileName(files[0]?.name);
      // const data = { file: files[0] };
      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files`, file);
      });

      dispatch(
        fileUpload({
          url: `crm/org/${orgId}/files/upload`,
          data: formData,
          params: {},
          onUploadProgress: handleUploadProgresss,
        })
      );
    }
  };

  const handleClose = () => {
    if (currentFile && currentFile?.length > 0) {
      const deletedUrl = currentFile?.map((file) => {
        return {
          url: file.url,
        };
      });
      dispatch(
        deleteFile({
          url: `crm/org/${orgId}/files/delete`,
          data: deletedUrl,
          params: {},
        })
      );
    }
    onClose();
  };

  const fileDelete = (name) => {
    if (currentFile && currentFile?.length > 0) {
      const deletedUrl = currentFile
        .filter((file, index) => file.name === name)
        .map((item) => {
          return { url: item.url };
        });
      dispatch(
        deleteFile({
          url: `crm/org/${orgId}/files/delete`,
          data: deletedUrl,
          params: {},
        })
      );
    }
  };

  // const handleIsPurchaseOrder = (filename) => {
  //   setIsPurchaseOrderFile(filename);
  // };

  useEffect(() => {
    if (openSource) {
      dispatch(
        getLeadSource({
          url: `crm/org/${orgId}/leads/source`,
          params: {},
        })
      );
    }
  }, [openSource]);

  useEffect(() => {
    if (openOffice) {
      dispatch(
        getLeadOffice({
          url: `crm/org/${orgId}/leads/offices`,
          params: {},
        })
      );
    }
  }, [openOffice]);
  const isEmpty = (obj) => Object.keys(obj).length === 0;

  useEffect(() => {
    setValue("address", leadData?.address);

    setValue("customerInfo.pContact", leadData?.customerInfo?.pContact ?? {});
    if (
      leadData?.customerInfo?.sContact &&
      !isEmpty(leadData?.customerInfo?.sContact)
    ) {
      setValue("customerInfo.sContact", leadData?.customerInfo?.sContact);
      setShowSecondary(true);
      setBtnDisabled(true);
    }

    if (leadData?.office) {
      setOfficeSelected(leadData?.office);
      setValue("office", leadData?.office);
    }

    if (leadData?.source) {
      setSourceSelected(leadData?.source);
      setValue("source", leadData?.source);
    }

    if (leadData?.description) {
      setOfficeSelected(leadData?.description);
      setValue("description", leadData?.description);
    }

    // if (leadSelected?.id) {
    //   setValue("leadId", leadSelected?.id);
    // }
  }, [leadData]);

  useEffect(() => {
    if (createData?.createSuccess) {
      onClose();
    }
  }, [createData]);

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">{t("New Lead")}</p>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal_body">
          <p className="error_message">{errors.address?.message}</p>

          <p className="new_project_name">{t("Select Address")}</p>
          <PlacesAutoComplete
            desc={leadData?.address}
            innerRef={register("address", { required: true })}
            onSelect={handleSelect}
          />

          <ContactForm
            value={leadData?.customerInfo?.pContact}
            register={register}
            errors={errors?.customerInfo?.pContact}
            type="pContact"
            showSecondary={showSecondary}
            btnDisabled={btnDisabled}
            onClick={(e) => {
              e.preventDefault();
              setShowSecondary(!showSecondary);
            }}
            onPhoneNumberChange={handlePhoneNumber}
          />
          {showSecondary && (
            <ContactForm
              value={leadData?.customerInfo?.sContact}
              register={register}
              errors={errors}
              type="sContact"
              onPhoneNumberChange={handlePhoneNumber}
            />
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h4 className="mb_0">{t("Sales Information")}</h4>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AutoCompleteAsync
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
                name="office"
                label={t("Office")}
                open={openOffice}
                onOpen={() => {
                  setOpenOffice(true);
                }}
                onClose={() => {
                  setOpenOffice(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value.id
                }
                loading={leadLoading}
                options={officeList}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setOfficeSelected(null);
                  } else {
                    setOfficeSelected(newValue);
                  }
                }}
                register={register}
                renderOption={(props, option) => (
                  <Box style={{ fontSize: 13 }} {...props}>
                    {option.name}
                  </Box>
                )}
                value={officeSelected}
                getOptionLabel={(option) => {
                  if (option.name) {
                    return option?.name;
                  } else {
                    return t("Please Select");
                  }
                }}
              />

              <AutoCompleteGroup
                name="salesRep"
                type="sale"
                label={t("Sales Rep")}
                register={register}
                onChange={handleGroup}
                value={leadData?.salesRep}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AutoCompleteAsync
                sx={{ width: "100%", marginBottom: "10px" }}
                name="source"
                label={t("Source")}
                open={openSource}
                onOpen={() => {
                  setOpenSource(true);
                }}
                onClose={() => {
                  setOpenSource(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value.id
                }
                loading={leadLoading}
                options={sourceList}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setSourceSelected(null);
                  } else {
                    setSourceSelected(newValue);
                  }
                }}
                register={register}
                renderOption={(props, option) => (
                  <Box style={{ fontSize: 13 }} {...props}>
                    {option.name}
                  </Box>
                )}
                value={sourceSelected}
                getOptionLabel={(option) => {
                  if (option.name) {
                    return option?.name;
                  } else {
                    return t("Please Select");
                  }
                }}
              />
              <AutoCompleteGroup
                name="setter"
                type="setter"
                label={t("Setter")}
                register={register}
                onChange={handleGroup}
                value={leadData?.setter}
              />
            </Grid>
          </Grid>
          <div
            style={{ marginTop: "32px", position: "relative", height: "250px" }}
          >
            <TextEditor
              name="description"
              label={t("Description")}
              register={register}
              value={content}
              onChange={handleDescription}
            />
          </div>
          {/* {id ? null : (
            <div style={{ marginTop: "32px", height: "300px" }}>
              <DragDropUploader
                onChange={handleFile}
                onDelete={fileDelete}
                progress={progress}
                // onIsPurchseOrder={handleIsPurchaseOrder}
              />
            </div>
          )} */}
        </div>
        <div className="modal_footer">
          <Button type="submit" className="primary_button">
            {t("Save")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default LeadForm;
