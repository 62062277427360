import { call, fork, put, takeLatest } from "redux-saga/effects";
import { createItem, editItem, getItem } from "../../services/service";
import { setDialog } from "../slices/dialogSlice";
import {
  setCreateFailed,
  setCreateSnackbar,
  setCreateSuccess,
} from "../slices/formSlice";
import {
  createAdditionalInfo,
  createAHJInfo,
  createFinancialInfo,
  createFolder,
  createHoaInfo,
  createPermitDetails,
  createPropertyInfo,
  createSystemInfo,
  getAdditionalInfo,
  getAHJInfo,
  getFinancialInfo,
  getFinSource,
  getFinType,
  getFolderList,
  getHoaInfo,
  getPermitDetails,
  getPermitStatus,
  getProjectStatus,
  getPropertyInfo,
  getSystemInfo,
  setAdditionalInfo,
  setAdditionalInfoLoadingFalse,
  setAHJInfo,
  setFinancialInfo,
  setFinSourceList,
  setFinTypeList,
  setFolderList,
  setHoaInfo,
  setPermitDetails,
  setPermitStatus,
  setProjectStatus,
  setPropertyInfo,
  setSystemInfo,
  updateProjectStatus,
} from "../slices/additionalInfoSlice";

function* onLoadAdditonalInfoAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setAdditionalInfo({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadAdditonalInfo() {
  yield takeLatest(getAdditionalInfo.type, onLoadAdditonalInfoAsync);
}

function* onLoadHoaInfoAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setHoaInfo({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadHoaInfo() {
  yield takeLatest(getHoaInfo.type, onLoadHoaInfoAsync);
}

function* onLoadFinancialInfoAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setFinancialInfo({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadFinancialInfo() {
  yield takeLatest(getFinancialInfo.type, onLoadFinancialInfoAsync);
}

function* onLoadSystemInfoAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setSystemInfo({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadSystemInfo() {
  yield takeLatest(getSystemInfo.type, onLoadSystemInfoAsync);
}

function* onLoadPropertyInfoAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setPropertyInfo({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadPropertyInfo() {
  yield takeLatest(getPropertyInfo.type, onLoadPropertyInfoAsync);
}

function* onLoadFolderListAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setFolderList({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadFolderList() {
  yield takeLatest(getFolderList.type, onLoadFolderListAsync);
}

function* onLoadProjectStatusAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setProjectStatus({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadProjectStatus() {
  yield takeLatest(getProjectStatus.type, onLoadProjectStatusAsync);
}

function* onLoadAHJInfoAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setAHJInfo({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadAHJInfo() {
  yield takeLatest(getAHJInfo.type, onLoadAHJInfoAsync);
}

function* onLoadPermitStatusAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });

    yield put(setPermitStatus([...response.data]));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadPermitStatus() {
  yield takeLatest(getPermitStatus.type, onLoadPermitStatusAsync);
}

function* onLoadPermitDetailsAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setPermitDetails({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadPermitDetails() {
  yield takeLatest(getPermitDetails.type, onLoadPermitDetailsAsync);
}

function* onLoadFinTypeAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });

    yield put(setFinTypeList(response.data));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadFinType() {
  yield takeLatest(getFinType.type, onLoadFinTypeAsync);
}

function* onLoadFinSourceAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });

    yield put(setFinSourceList(response.data));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadFinSource() {
  yield takeLatest(getFinSource.type, onLoadFinSourceAsync);
}

function* onCreateAdditionalInfoAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess: response?.status === 200 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreateAdditionalInfo() {
  yield takeLatest(createAdditionalInfo.type, onCreateAdditionalInfoAsync);
}

function* onCreateHoaInfoAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess: response?.status === 200 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreateHoaInfo() {
  yield takeLatest(createHoaInfo.type, onCreateHoaInfoAsync);
}

function* onCreateFinancialInfoAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess: response?.status === 200 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreateFinancialInfo() {
  yield takeLatest(createFinancialInfo.type, onCreateFinancialInfoAsync);
}

function* onCreateSystemInfoAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess: response?.status === 200 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreateSystemInfo() {
  yield takeLatest(createSystemInfo.type, onCreateSystemInfoAsync);
}

function* onCreatePropertyInfoAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess:
          response?.status === 200 || response?.status === 201 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreatePropertyInfo() {
  yield takeLatest(createPropertyInfo.type, onCreatePropertyInfoAsync);
}

function* onCreateFolderAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess:
          response?.status === 200 || response?.status === 201 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreateFolder() {
  yield takeLatest(createFolder.type, onCreateFolderAsync);
}

function* onUpdateProjectStatusAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess:
          response?.status === 200 || response?.status === 201 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onUpdateProjectStatus() {
  yield takeLatest(updateProjectStatus.type, onUpdateProjectStatusAsync);
}

function* onCreateAHJInfoAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess:
          response?.status === 200 || response?.status === 201 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreateAHJInfo() {
  yield takeLatest(createAHJInfo.type, onCreateAHJInfoAsync);
}

function* onCreatePermitDetailsAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess: response?.status === 200 ? true : false,
        statusCode: response?.status,
        params: params,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreatePermitDetails() {
  yield takeLatest(createPermitDetails.type, onCreatePermitDetailsAsync);
}

export const additionalInfoSagas = [
  fork(onLoadAdditonalInfo),
  fork(onLoadHoaInfo),
  fork(onLoadFinancialInfo),
  fork(onLoadSystemInfo),
  fork(onLoadPropertyInfo),
  fork(onLoadFolderList),
  fork(onLoadProjectStatus),
  fork(onLoadAHJInfo),
  fork(onLoadPermitDetails),
  fork(onLoadFinType),
  fork(onLoadFinSource),
  fork(onCreateAdditionalInfo),
  fork(onCreateHoaInfo),
  fork(onCreateFinancialInfo),
  fork(onCreateSystemInfo),
  fork(onCreatePropertyInfo),
  fork(onCreateFolder),
  fork(onUpdateProjectStatus),
  fork(onCreateAHJInfo),
  fork(onCreatePermitDetails),
  fork(onLoadPermitStatus),
];
