import CardTitle from "../../../dashboard/CardTitle";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { modalStyle } from "../../../constants/ConstData";
import PermittingForm from "./PermittingForm";
import BasicModal from "../../../modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Storage from "../../../../utils/Storage";
import { getPermitDetails } from "../../../../redux/slices/additionalInfoSlice";
import Loading from "../../../loading/Loading";
import LabelValue from "../LabelValue";
import { useTranslation } from "react-i18next";

const PermittingInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const permitDetails = useSelector(
    (state) => state.additionalInfo.permitDetails
  );
  const loading = useSelector((state) => state.additionalInfo.loading);
  const permitLoading = useSelector(
    (state) => state.additionalInfo.permitLoading
  );

  const snackbarData = useSelector((state) => state.form.snackbarData);
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;

  const { id } = useParams();
  const dispatch = useDispatch();

  const editPermitting = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const loadAdditionalInfo = () => {
    dispatch(
      getPermitDetails({
        url: `/crm/org/${orgId}/proj/${id}/info?data=permitdtl`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (
      snackbarData?.createSuccess &&
      snackbarData?.params?.data === "permitdtl"
    ) {
      console.log("params=----------->", snackbarData?.params);
      handleClose();
      loadAdditionalInfo();
    }
  }, [snackbarData]);

  if (loading || permitLoading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      <CardTitle title={t("Permitting")} type="project_view">
        <IconButton className="marginL10" size="small" onClick={editPermitting}>
          <Edit />
        </IconButton>
      </CardTitle>
      <div className="card_content">
        <div>
          {/* <LabelValue
            className="m_0"
            label="Flow Status"
            value={permitDetails?.status}
            buttonLabel="Add Flow Status"
            onClick={editPermitting}
          />
          <LabelValue
            label="Contractor Assigned"
            value={permitDetails?.contractorAssigned}
            buttonLabel="Add Contractor Assigned"
            onClick={editPermitting}
            // type="currency"
          /> */}
          <LabelValue
            label={t("Permitting Notes")}
            value={permitDetails?.permittingNotes}
            buttonLabel="Add Permitting Notes"
            onClick={editPermitting}
          />

          <LabelValue
            label="Building Permit Number"
            value={permitDetails?.buildingPermit}
            buttonLabel="Add Building Permit Number"
            onClick={editPermitting}
          />
          <LabelValue
            label={t("Electrical Permit Number")}
            value={permitDetails?.electricalPermit}
            buttonLabel="Add Electrical Permit Number"
            onClick={editPermitting}
          />
          <LabelValue
            label={t("Zoning Permit Number")}
            value={permitDetails?.zoningPermit}
            buttonLabel="Add Zoning Permit Number"
            onClick={editPermitting}
          />
          <LabelValue
            label={t("Other Permit Number")}
            value={permitDetails?.otherPermit}
            buttonLabel="Add Other Permit Number"
            onClick={editPermitting}
          />
          <LabelValue
            label={t("Building Permit Cost")}
            value={permitDetails?.buildingPermitCost}
            buttonLabel="Add Building Permit Cost"
            onClick={editPermitting}
            type="currency"
          />
          <LabelValue
            label={t("Electrical Permit Cost")}
            value={permitDetails?.electricalPermitCost}
            buttonLabel="Add Electrical Permit Cost"
            onClick={editPermitting}
            type="currency"
          />
          <LabelValue
            label={t("Zoning Permit Cost")}
            value={permitDetails?.zoningPermitCost}
            buttonLabel="Add Zoning Permit Cost"
            onClick={editPermitting}
            type="currency"
          />
          <LabelValue
            label={t("Other Permit Cost")}
            value={permitDetails?.otherPermitCost}
            buttonLabel="Add Other Permit Cost"
            onClick={editPermitting}
            type="currency"
          />
        </div>
        <BasicModal
          isOpen={isOpen}
          onClose={handleClose}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "600px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <PermittingForm
            // id={id}
            // projectData={projectData}
            permittingDetails={permitDetails}
            onClose={handleClose}
          />
        </BasicModal>
      </div>
    </div>
  );
};

export default PermittingInfo;
