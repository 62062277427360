import React, { useEffect, useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import Button from "../../../components/buttons/Button";
import PlusIcon from "../../../assets/images/plus";
import ToolBar from "../../../components/toolbar/ToolBar";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import DataTable from "../../../components/table/DataTable";
import { getGroupList } from "../../../redux/slices/orgSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AddGroupsModal from "../view/AddGroupsModal";
import Loading from "../../../components/loading/Loading";
import { useTranslation } from "react-i18next";

const Groups = () => {
  const {t}=useTranslation();
  const groupColumns = [
    {
      field: "slno",
      headerName: t("Sl No."),
      width: 80,
    },
    {
      field: "id",
      headerName: t("Group Id"),
      width: 80,
    },
    {
      field: "mame",
      headerName: t("Group Name"),
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },
  
    {
      field: "description",
      headerName: t("Description"),
      width: 300,
      renderCell: (params) => <Typography>{params?.row?.desc}</Typography>,
    },
    // {
    //   //field: "actions",
    //  // headerName: "Actions",
    //   width: 100,
    //   renderCell: (params) => {
    //     const handleEditClick = (e) => {
    //       e.stopPropagation();
    //       console.log('inside handle edit click icon');
    //       // Implement your edit logic here
    //       console.log(`Edit clicked for row ${params.row.id}`);
    //     };
    //   //  const [openEditGroupModal,setOpenEditGroupModal]=useState(false);
    //     return (
    //       <IconButton onClick={handleEditClick} color="primary">
    //         <CustomEditIcon onClick={(e)=>{
    //           console.log('inside custom edit icon click fn')
    //           console.log('params',params.row)
    //           //setOpenEditGroupModal(true)
  
    //             e.stopPropagation();
    //         }}/>
    //       </IconButton>
    //     );
    //   },
    // },
  ];
  const {id}=useParams();
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [openAddGroupsModal, setOpenAddGroupsModal] = useState(false);
  const group = useSelector((state) => state.org.group);
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const groupLoading=useSelector((state)=>state.org.groupLoading);
  const loadGroups = () => {
    dispatch(
      getGroupList({
        url: `crm/user/groups/organization/${id}`,
        params: {},
      })
    );
  };
  useEffect(() => {
    loadGroups();
  }, [snackbarData]);
  return (
    <>
      <CardItem height="890px">
        {
          <div className="card_container">
          <div style={{ padding: "20px" }}>
            <ToolBar title={t("Groups")}>
              <div className="d_flex align_items_center justify_content_between">
                <Button
                  type="submit"
                  className="primary_button d_flex align_items_center"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    padding: "5px 20px",
                  }}
                  leftIcon={<PlusIcon color="#fff" />}
                  onClick={() => setOpenAddGroupsModal(true)}
                >
                  {t("Add Group")}
                </Button>
              </div>
            </ToolBar>
          </div>

          <div style={{ padding: "20px" }}>
            <DataTable 
              rows={[...group].map((item,index)=>{
                return {
                  ...item,
                  slno:index+1
                }
              })}
              columns={groupColumns}
              onRowClick={(row) => {
                console.log('row',row);
                navigate(`/org/${id}/groups/${row?.id}`,{
                  state:{
                    groupDetailsToshow:row.row
                  }
                })
              }}
            />
          </div>
        </div>
        }

        <AddGroupsModal
        open={openAddGroupsModal}
        close={() => setOpenAddGroupsModal(false)}
        orgId={id}
      />
      </CardItem>
    </>
  );
};

export default Groups;
