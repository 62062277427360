import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/slices/titleSlice";
import "./style.scss";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import ToolBar from "../../components/toolbar/ToolBar";
import ProgressCard from "../../components/dashboard/ProgressCard";
import CustomerIcon from "../../assets/images/customers.svg";
import RevenueIcon from "../../assets/images/revenue.svg";
import ConversioIcon from "../../assets/images/conversion.svg";
import TotalDealsIcon from "../../assets/images/totaldeals.svg";
import InfoCard from "../../components/dashboard/InfoCard";
import DealersCard from "../../components/dashboard/DealersCard";
import DealsCard from "../../components/dashboard/DealsCard";
import RevenueAnalytics from "../../components/dashboard/RevenueAnalytics";
import ProjectStatistics from "../../components/dashboard/ProjectStatistics";
import Button from "../../components/buttons/Button";
import FilterIcon from "../../assets/images/filter";
import ExportIcon from "../../assets/images/export";
import { CardItem } from "../../utils/CardItem";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../components/dialog/CustomDialog";
import Storage from "../../utils/Storage";
import { updateDashboard } from "../../redux/slices/dashboardSlice";
import { dateFilter } from "../../components/constants/ConstData";
import DoneIcon from "@mui/icons-material/Done";
import { getDateRange } from "../../utils/getDateRange";
import GroupIcon from "@mui/icons-material/Group";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import CountCard from "../../components/dashboard/CountCard";
import dayjs from "dayjs";
import Loading from "../../components/loading/Loading";
import StateProjectStatistics from "../../components/dashboard/StateProjectStatistics";
import ConversionProjectStatistics from "../../components/dashboard/ConversionProjectStatistics";
import DeliveryProjectStatistics from "../../components/dashboard/DeliveryProjectStatistics";

const Dashboard = () => {
  const dispatch = useDispatch();

  const data = [{ name: "progress", value: 0 }];
  const userDetails = useSelector((state) => state.auth.userDetails);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const orgInfo = useSelector((state) => state.auth.orgInfo);
  const orgLoading = useSelector((state) => state.org.loading);
  const mobileVerify = Storage.read("mobileVerify");
  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const dashboardData = useSelector((state) => state.dashboard.dashboardData);
  const loading = useSelector((state) => state.dashboard.loading);

  // const systemcost = useSelector((state) => state.dashboard.systemcost);

  // const [customerDetails, setCustomerDetails] = useState();
  const [dashboardDetails, setDashboardDetails] = useState({});

  const [openFilter, setOpenFilter] = useState(null);

  const [filterValue, setFilterValue] = useState({
    basedby: "week",
    name: "This Month",
  });

  const [dateRange, setDateRange] = useState(null);

  const lineData = [
    {
      name: "1",
      pv: 2400,
    },
    {
      name: "2",
      pv: 1398,
    },
    {
      name: "3",
      pv: 9800,
    },
    {
      name: "4",
      pv: 3908,
    },
  ];

  const barData = [
    {
      x: "1",
      y: 2400,
    },
    {
      x: "2",
      y: 1398,
    },
    {
      x: "3",
      y: 9800,
    },
    {
      x: "4",
      y: 3908,
    },
    {
      x: "3",
      y: 9400,
    },
    {
      x: "4",
      y: 5908,
    },
  ];

  const handleCancel = () => {
    setDialogOpen(false);
    Storage.write("mobileVerify", false);
  };

  const handleFilters = (event) => {
    event.preventDefault();

    setOpenFilter(event.currentTarget);
  };

  const handleDateFilter = (filter) => {
    setFilterValue(filter);

    handleCloseFilterMenu();
  };

  const handleCloseFilterMenu = () => {
    setOpenFilter(null);
  };

  useEffect(() => {
    dispatch(setTitle("Dashboard"));
  }, []);

  useEffect(() => {
    if (orgId && filterValue) {
      const newRange = getDateRange(filterValue);
      setDateRange(newRange);
    }
  }, [filterValue, orgId]);

  useEffect(() => {
    if (dateRange) {
      const newData = {
        details: [
          {
            cusdtls: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            systemsize: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            systemcost: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            topstate: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            topsales: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            topsetter: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            projectchart: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            totalleads: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            totalprojects: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            leadsconverted: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
          {
            projectsourcechart: true,
            basedby: filterValue?.basedby,
            date_range: dateRange,
          },
        ],
      };

      dispatch(
        updateDashboard({
          url: `/crm/org/${orgId}/dashboard/user`,
          data: newData,
          params: {},
        })
      );
    }
  }, [dateRange]);

  useEffect(() => {
    if (orgInfo && orgInfo.length === 0) {
      navigate("/org/create");
    }
  }, [orgInfo]);

  useEffect(() => {
    if (!mobileVerify && !userDetails?.phoneNumber) {
      Storage.write("mobileVerify", true);
    }
  }, [userInfo, userDetails]);

  useEffect(() => {
    if (mobileVerify) {
      setDialogOpen(true);
    }
  }, [mobileVerify]);

  const loadYear = (dashData) => {
    const date = dayjs(dateRange?.from); // Create a Day.js date instance
    const year = date.year();
    return dashData[year][0];
  };

  useEffect(() => {
    if (dashboardData) {
      const customerDetails = loadYear(dashboardData?.cusdtls);
      const systemcost = loadYear(dashboardData?.systemcost);
      const systemsize = loadYear(dashboardData?.systemsize);
      const totalleads = loadYear(dashboardData?.totalleads);
      const totalprojects = loadYear(dashboardData?.totalprojects);
      const leadsconverted = loadYear(dashboardData?.leadsconverted);

      setDashboardDetails({
        ...dashboardDetails,
        customerDetails: customerDetails,
        systemcost: systemcost,
        systemsize: systemsize,
        totalleads: totalleads,
        totalprojects: totalprojects,
        leadsconverted: leadsconverted,
      });
    }
  }, [dashboardData]);

  useEffect(() => {
    console.log("dashboardDetails----------------------->", dashboardDetails);
  }, [dashboardDetails]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="dashboard">
      <ToolBar
        title={`Welcome ${userDetails.displayName} !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`}
        subTitle="Track your sales activity, leads and deals here."
      >
        <div className="d_flex align_items_center">
          <Button
            type="submit"
            className="default_button d_flex align_items_center marginR10"
            leftIcon={<FilterIcon color="#75787D" />}
            onClick={handleFilters}
          >
            {filterValue?.name}
          </Button>
          <Menu
            sx={{
              mt: "30px",
              ".css-6hp17o-MuiList-root-MuiMenu-list": {
                width: "150px",
              },
            }}
            style={{ height: "400px" }}
            id="menu-appbar"
            anchorEl={openFilter}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(openFilter)}
            onClose={handleCloseFilterMenu}
          >
            {dateFilter?.map((filter, index) => (
              <MenuItem
                key={index}
                onClick={() => handleDateFilter(filter)}
                // {...register("statusId", {})}
              >
                {/* <IconButton sx={{ p: 0, marginRight: "8px" }}>
                          <Avatar
                            style={{ width: "30px", height: "30px" }}
                            {...stringAvatar(user?.name)}
                          />
                        </IconButton> */}
                <div className="d_flex align_items_center w_100">
                  <div
                    style={{
                      width: "20%",
                      // display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    {filter?.name === filterValue?.name && (
                      <DoneIcon fontSize="12px" color="success" />
                    )}
                  </div>
                  <div style={{ width: "80%" }}>
                    <Typography className="font_12 m_0">
                      {filter?.name}
                    </Typography>
                  </div>
                </div>
              </MenuItem>
            ))}
          </Menu>
          {/* <Button
            type="submit"
            className="primary_button d_flex align_items_center marginR10"
            leftIcon={<FilterIcon color="#fff" />}
          >
            Filters
          </Button> */}
          <Button
            type="submit"
            className="primary_button d_flex align_items_center"
            leftIcon={<ExportIcon color="#fff" />}
          >
            Export
          </Button>
        </div>
      </ToolBar>
      <Grid sx={{ marginTop: "16px" }} container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CardItem
            overlayColor="rgba(0, 0, 0, 0.2)"
            backgroundColor="#28a745"
            height="100px"
          >
            <CountCard
              title="Pipe Line Projects"
              countdtls={dashboardDetails?.totalprojects?.countdtls}
              icon={<GroupIcon />}
              type="count"
            />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CardItem
            overlayColor="rgba(0, 0, 0, 0.2)"
            backgroundColor="#6f42c1"
            height="100px"
          >
            <CountCard
              title="Revenue"
              countdtls={dashboardDetails?.systemcost?.systemcost}
              icon={<MonetizationOnIcon />}
              type="system_cost"
            />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CardItem
            overlayColor="rgba(0, 0, 0, 0.2)"
            backgroundColor="#007bff"
            height="100px"
          >
            <CountCard
              title="CUSTOMER"
              countdtls={dashboardDetails?.customerDetails?.countdtls}
              icon={<FitScreenIcon />}
              type="count"
            />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <CardItem
            overlayColor="rgba(0, 0, 0, 0.2)"
            backgroundColor="#fd7e14"
            height="100px"
          >
            <CountCard
              title="Converted"
              countdtls={dashboardDetails?.leadsconverted?.countdtls}
              icon={<GroupIcon />}
              type="count"
            />
          </CardItem>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={12} lg={4}>
          <CardItem height="200px">
            <ProgressCard data={data} />
          </CardItem>
          <CardItem height="420px">
            <DealersCard />
          </CardItem>
          <CardItem height="420px">
            <DealsCard />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CardItem height="200px">
                <InfoCard
                  id="info_one"
                  icon={CustomerIcon}
                  title="Total Leads"
                  // count="_ _ _"
                  data={dashboardDetails?.totalleads?.countdtls}
                  percent="_%"
                  percentColor="#189d3d"
                  gradient={{ color1: "#422D70", color2: "#845ADF" }}
                  type="count"
                />
              </CardItem>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CardItem height="200px">
                <InfoCard
                  id="info_two"
                  icon={RevenueIcon}
                  title="Total Projects"
                  // count="$_ _ _"
                  data={dashboardDetails?.totalprojects?.countdtls}
                  percent="_%"
                  percentColor="#23B7E5"
                  gradient={{ color1: "#125C73", color2: "#23B7E5" }}
                  type="count"
                />
              </CardItem>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CardItem height="200px">
                <InfoCard
                  id="info_three"
                  icon={ConversioIcon}
                  title="Converted"
               
                  data={dashboardDetails?.leadsconverted?.countdtls}
                  percent="_%"
                  percentColor="#26BF94"
                  gradient={{ color1: "#13604A", color2: "#26BF94" }}
                  type="count"
                />
              </CardItem>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CardItem height="200px">
                <InfoCard
                  id="info_four"
                  icon={TotalDealsIcon}
                  title="Total Customers"
                
                  data={dashboardDetails?.customerDetails?.countdtls}
                  percent="_%"
                  percentColor="#F5B849"
                  gradient={{ color1: "#7B5C25", color2: "#F5B849" }}
                  type="count"
                />
              </CardItem>
            </Grid>
          </Grid>
          <CardItem height="640px">
            <RevenueAnalytics
              data={dashboardDetails?.totalprojects?.countdtls}
            />
          </CardItem>
        </Grid> */}
        {/* <Grid item xs={12} sm={12} md={12} lg={9}>
          <CardItem height="478px" showscrollbar="hidden">
            <ProjectStatistics />
          </CardItem>
        </Grid> */}
        {/* <Grid item xs={12} sm={12} md={12} lg={3}>
          <CardItem height="478px" showscrollbar="auto">
            <ActivityCard showTitle={true} />
          </CardItem>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <CardItem height="289px" showscrollbar="auto">
            <ConversionProjectStatistics />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <CardItem height="289px" showscrollbar="auto">
            <DeliveryProjectStatistics />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <CardItem height="500px" showscrollbar="auto">
            <RevenueAnalytics
              data={dashboardDetails?.totalprojects?.countdtls}
            />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <CardItem height="500px" showscrollbar="auto">
            <StateProjectStatistics />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <CardItem height="420px">
            <DealersCard />
          </CardItem>
        </Grid>
      </Grid>
      <CustomDialog
        open={dialogOpen}
        title="Add Mobile"
        message="You may not get your mobile notification until you add and verify your mobile number"
        okText="Add Mobile Number"
        cancelText="I will do later"
        onOk={() => navigate("/user/profile")}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default Dashboard;
