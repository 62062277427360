import React, { useEffect, useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import ToolBar from "../../../components/toolbar/ToolBar";
import Button from "../../../components/buttons/Button";
import DataTable from "../../../components/table/DataTable";
import { getPendingUserListOrg } from "../../../redux/slices/orgSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import PlusIcon from "../../../assets/images/plus";
import InviteUserModal from "../view/InviteUserModal";
import { useTranslation } from "react-i18next";

const Invites = () => {
  const {t}=useTranslation();
  const pendingUsersColumn = [
    {
      field: "slno",
      headerName: t("Sl No."),
      width: 80,
    },
    {
      field: "id",
      headerName: t("User Id"),
      width: 80,
    },
  
    {
      field: "email",
      headerName: t("Email"),
      width: 300,
      renderCell: (params) => {
        return <Typography>{params?.row?.email}</Typography>;
      },
    },
  ];
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openUserInviteModal, setOpenUserInviteModal] = useState(false);
  const pendingUserList = useSelector((state) => state.org.pendingUserListOrg);
  const createData = useSelector((state) => state.form.createData);
  const snackbarData = useSelector((state) => state.form.snackbarData);

  const loadPendingUsers = () => {
    dispatch(
      getPendingUserListOrg({
        url: `/crm/user/organization/${id}/users`,
        params: {
          orgId: id,
          isPending: 1,
        },
      })
    );
  };
  useEffect(() => {
    loadPendingUsers();
  }, [snackbarData]);
  return (
    <>
      <CardItem height="890px">
        <div className="card_container">
          {/* <CardTitle title={selectedLink} type={"project_view"}></CardTitle> */}
          <div style={{ padding: "20px" }}>
            <ToolBar title={t("Invites")}>
              <div className="d_flex align_items_center justify_content_between">
                <Button
                  type="submit"
                  className="primary_button d_flex align_items_center"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "18px",
                    padding: "5px 20px",
                  }}
                  leftIcon={<PlusIcon color="#fff" />}
                  onClick={() => setOpenUserInviteModal(true)}
                >
                  {t("Invite User")}
                </Button>
              </div>
            </ToolBar>
          </div>

          <div style={{ padding: "20px" }}>
            <DataTable
              rows={[
                ...pendingUserList.map((item,index) => {
                  return {
                    ...item,
                    id: item.userId,
                    slno:index+1
                  };
                }),
              ]}
              columns={pendingUsersColumn}
          
            />
          </div>
        </div>
        <InviteUserModal
        open={openUserInviteModal}
        close={() => setOpenUserInviteModal(false)}
        orgId={id}
      />
      </CardItem>
    </>
  );
};

export default Invites;
