import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import Search from "./Search";
import "./style.scss";

let service;
const libraries = ["places", "marker"];

const mapContainerStyle = {
  height: "300px",
  width: "100%",
};
const options = {
  // disableDefaultUI: true,
  zoomControl: true,
  mapTypeId: "satellite",
  mapId: process.env.REACT_APP_GOOGLE_MAPS_MAPID,

  // zoom: 30,
};
// const center = {
//   lat: 43.6532,
//   lng: -79.3832,
// };

export default function PlacesAutoComplete({
  desc,
  onSelect,
  innerRef,
  hideMap,
  placeholder,
}) {
  const google = (window.google = window.google ? window.google : {});
  const [center, setCenter] = useState({
    lat: 43.6532,
    lng: -79.3832,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(30);
    let map = mapRef.current;

    let request = {
      location: { lat, lng },
      radius: "500",
    };
    service = new google.maps.places.PlacesService(mapRef.current);
    service.nearbySearch(request, callback);
    function callback(results, status) {
      console.log("results=---------->", results);
      console.log("status=---------->", status);
      console.log(
        "google.maps.places.PlacesServiceStatus.OK=--------->",
        google.maps.places.PlacesServiceStatus.OK
      );

      if (status === google.maps.places.PlacesServiceStatus.OK) {
        for (let i = 0; i < results.length; i++) {
          let place = results[i];
          new google.maps.marker.AdvancedMarkerElement({
            position: place.geometry.location,
            map,
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (desc === "") {
      setCenter({
        lat: 43.6532,
        lng: -79.3832,
      });
    }
  }, [desc]);

  return (
    isLoaded && (
      <div className="marginB10 map_places">
        <Search
          panTo={panTo}
          description={desc}
          onSelect={onSelect}
          innerRef={innerRef}
          placeholder={placeholder ?? "Search Address Here ..."}
          hideMap={hideMap ?? false}
        />
        {!hideMap && (
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={8}
            center={center}
            options={options}
            onLoad={onMapLoad}
          />
        )}
      </div>
    )
  );
}
