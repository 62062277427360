import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '../../../assets/images/close';
import PlusIcon from "../../../assets/images/plus";
import Button from '../../../components/buttons/Button';
import { modalStyle } from '../../../components/constants/ConstData';
import BasicModal from '../../../components/modal/Modal';
import DataTable from '../../../components/table/DataTable';
import CustomAutoComplete from '../../../fields/autoComplete/CustomAutoComplete';
import { addUsersToGroup, getUsersInGroup, removeUsersFromGroup } from '../../../redux/slices/groupSlice';
import { CardItem } from '../../../utils/CardItem';
import CustomBreadcrumbOrg from '../view/CustomBreadCrumbOrg';
import { useTranslation } from 'react-i18next';
const columns = [
    {
      field: "id",
      headerName: "User Id",
      width: 80,
    },
    {
      field: "mame",
      headerName: "User Name",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },
  
    {
      field: "email",
      headerName: "Email",
      width: 300,
      renderCell: (params) => <Typography>{params?.row?.email}</Typography>,
    },
    {
      field: "user_type",
      headerName: "User Type",
      width: 300,
      renderCell: (params) => {
        let userType = "User";
        if (params.row.isOwner === "true") userType = "Owner";
        else if (params?.row?.isModerator == "true") userType = "Moderator";
  
        return <Typography>{userType}</Typography>;
      },
    },
  ];
const GroupDetails = () => {
  const {t}=useTranslation();
  const [rowsSelected, setRowsSelected] = useState([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [userSelected, setUserSelected] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const snackbarData = useSelector((state) => state.form.snackbarData);

  const dispatch=useDispatch();
  const {id,group_id}=useParams();
  const groupUsers = useSelector((state) => state.group.usersInGroup);
  const userList = useSelector((state) => state.org.userListOrg);
  const navigate=useNavigate();
  const location=useLocation();
  const { groupDetailsToshow } = location.state || {};
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
  });
    const breadcrumbs = [
        <Link key="1" to={`/org/${id}/groups`} >
          <b>{groupDetailsToshow?.name}</b>
        </Link>,
        <Typography key="2" color="text.primary">
          {t("Users")}
        </Typography>,
      ];

      const removeUser = () => {
        let usersToRemove = rowsSelected.map((item, index) => {
          return {
             id: groupUsers.filter((row) => row.id == item)[0].grpmapid,
            map_userids: item,
            active_status: 0,
          };
        });

        dispatch(
          removeUsersFromGroup({
            url: `/crm/org/${id}/groups/${group_id}/users`,
            data: {
              usrdtl: usersToRemove,
            },
          })
        );
      };

      const addUserToGroup = (data) => {
        if (userSelected) {
          dispatch(
            addUsersToGroup({
              url: `/crm/org/${id}/groups/${group_id}/users`,
              data: {
                usrdtl: [
                  {
                    id: 0,
                    map_userids: userSelected.userId,
                    active_status: 1,
                  },
                ],
              },
            })
          );
           setOpenAddUserModal(false);
           reset();
        } else {
          setOpenAddUserModal(false);
        }
      };
      const getUserListOptions = () => {
        let result = userList.filter(
          (largeItem) =>
            !groupUsers.some((smallItem) => largeItem.userId === smallItem.id)
        );
        return result.map((item) => {
          return {
            ...item,
            id: item.userId,
          };
        });
      };
      const getUsers = () => {
        dispatch(
          getUsersInGroup({
            url: `crm/user/groups/${group_id}/users`,
            params: {},
          })
        );
      };
      useEffect(() => {
        getUsers();
        reset();
      }, [snackbarData]);
  return (
   <>
     <div className="">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CardItem height="890px">
            <div className="card_container" style={{ padding: "20px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <CustomBreadcrumbOrg breadcrumbs={breadcrumbs} />
                </div>
                <div>
                  <Button
                    type="submit"
                    className="primary_button d_flex align_items_center"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "18px",
                      padding: "5px 20px",
                    }}
                    leftIcon={<PlusIcon color="#fff" />}
                    onClick={() => {
                      setOpenAddUserModal(true);
                    }}
                  >
                    {t("Add User")}
                  </Button>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <DataTable
                  rows={[...groupUsers]}
                  columns={columns}
                  checkboxSelection={(params) => {
                  
                  }}
                  onRowSelectionModelChange={(row) => {
                    setRowsSelected(row);
                  }}
                  disableSelectionOnClick={(params) => {
                    return params.row.disableSelection || false;
                  }}
                  isRowSelectable={(params) => params.row.isOwner !== "true"}
                />
              </div>
              <div style={{ padding: "20px" }}>
                {rowsSelected.length > 0 && (
                  <>
                    <Button
                      className="primary_button marginT10"
                      onClick={removeUser}
                    >
                      {t("Remove User")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </CardItem>
        </Grid>
      </Grid>
      <BasicModal
        isOpen={openAddUserModal}
        onClose={() => setOpenAddUserModal(false)}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "400px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">{t("Add User")}</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={() => setOpenAddUserModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(addUserToGroup)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label>{t("User")}</label>
                      <CustomAutoComplete
                        name="groupId"
                        className="user_access_level"
                        open={openDropDown}
                        onOpen={() => {
                          setOpenDropDown(true);
                        }}
                        onClose={() => {
                          setOpenDropDown(false);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value.id
                        }
                        // loading={loading}
                        options={[...getUserListOptions()]}
                        onChange={(event, newValue) => {
                          setUserSelected(newValue);
                          //setGroupSelected(newValue.id);
                        }}
                        register={register}
                        renderOption={(props, option) => (
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option.name}
                          </Box>
                        )}
                        //value={groupSelected}
                        getOptionLabel={(option) => {
                          if (option.name) {
                            return option?.name;
                          } else {
                            return t("Please Select");
                          }
                        }}
                     
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    {t("Add User")}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </>
      </BasicModal>
    </div>
   </>
  )
}

export default GroupDetails