import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CloseIcon from "../../../assets/images/close";
import AssignToIcon from "../../../assets/images/assignto.svg";
import CreateDateIcon from "../../../assets/images/createdate.svg";
import DueDateIcon from "../../../assets/images/duedate.svg";
import ProjectAssignDetails from "../../../components/project/create/ProjectAssignDetails";
import TextEditor from "../../../fields/texteditor/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PlacesAutoComplete from "../../../components/maps/PlacesAutoComplete";
import { Controller, useForm, useFormState } from "react-hook-form";
import "./style.scss";
import ContactForm from "../../../components/customer/ContactForm";
import Button from "../../../components/buttons/Button";
import dayjs from "dayjs";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import {
  getLead,
  getLeadList,
  getLeadOffice,
  getLeadSource,
} from "../../../redux/slices/leadSlice";
import AutoCompleteGroup from "../../../fields/autoComplete/AutoCompleteGroup";
import { getOrgUserList } from "../../../redux/slices/orgSlice";
import { dirtyValues, stringAvatar } from "../../../utils/util";
import {
  createNewProject,
  editProject,
  getProjectStatusList,
} from "../../../redux/slices/projectSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Storage from "../../../utils/Storage";
import { deleteFile, fileUpload } from "../../../redux/slices/fileUploadSlice";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  address: yup.string().required("Please Select an address"),
  customerInfo: yup.object().shape({
    pContact: yup.object().shape({
      firstName: yup.string().required("FirstName is required"),
      lastName: yup.string().required("LastName is required"),
      email: yup
        .string()
        .matches(
          /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
          "Enter a valid email address"
        )
        .required("Email is required"),
      mobile: yup.string().required("Mobile is required"),
    }),
    sContact: yup.object().shape({
      firstName: yup.string(),
      lastName: yup.string(),
      email: yup
        .string()
        .matches(
          /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
          "Enter a valid email address"
        ),
      mobile: yup.string(),
    }),
  }),
  assignedTo: yup.object().required("Assignee is required"),
  statusId: yup.string().required("Status is required"),
});

// const leadList = [
//   {
//     address: "1621 Aspen Glen Drive, Hamden, CT, USA ",
//     id: "32322333",
//     createdDate: "25-09-2023",
//     pcontact: {
//       firstName: "Siraj",
//       lastName: "KP",
//       email: "ksiraj@energyscaperenewables.com",
//       mobile: "9946860350",
//     },
//     scontact: {
//       firstName: "Shoukath",
//       lastName: "Ali",
//       email: "shouka@gmail.com",
//       mobile: "9946860450",
//     },
//     office: "SkyNet HQ",
//     setter: "Joseph",
//     salesRep: "Shoukath",
//     source: "Company Referral",
//   },
//   {
//     address: "1622 Aspen Glen Drive, Hamden, CT, USA",
//     id: "32322334",
//     createdDate: "24-09-2023",
//     pcontact: {
//       firstName: "Siraj",
//       lastName: "KP",
//       email: "ksiraj@energyscaperenewables.com",
//       mobile: "9946860350",
//     },
//     scontact: {
//       firstName: "Shoukath",
//       lastName: "Ali",
//       email: "shouka@gmail.com",
//       mobile: "9946860450",
//     },
//     office: "SkyNet HQ",
//     setter: "Joseph",
//     salesRep: "Shoukath",
//     source: "Company Referral",
//   },
//   {
//     address: "1620 Aspen Glen Drive, Hamden, CT, USA",
//     id: "32322335",
//     createdDate: "23-09-2023",
//     pcontact: {
//       firstName: "Siraj",
//       lastName: "KP",
//       email: "ksiraj@energyscaperenewables.com",
//       mobile: "9946860350",
//     },
//     scontact: {
//       firstName: "Shoukath",
//       lastName: "Ali",
//       email: "shouka@gmail.com",
//       mobile: "9946860450",
//     },
//     office: "SkyNet HQ",
//     setter: "Joseph",
//     salesRep: "Shoukath",
//     source: "Company Referral",
//   },
// ];

const ProjectForm = ({ projectData, id, onClose, leadValue }) => {
  const { t } = useTranslation();
  const settings = [
    { name: "Siraj" },
    { name: "Joel" },
    { name: "Shoukath" },
    { name: "Ranjith" },
  ];
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);

  const [openDate, setOpenDate] = useState(false);
  const [assignee, setAssignee] = useState();
  const [status, setStatus] = useState();

  const [showSecondary, setShowSecondary] = useState(false);
  const [leadSelected, setLeadSelected] = useState(leadValue ?? null);
  const [desc, setDesc] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [createdDate, setCreatedDate] = useState(
    dayjs(new Date()).format("MM/DD/YYYY")
  );

  const [dueDate, setDueDate] = useState(new Date());
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;

  const leadList = useSelector((state) => state.lead.leadList);
  const leadLoading = useSelector((state) => state.lead.loading);
  const loading = useSelector((state) => state.project.loading);
  const statusLoading = useSelector((state) => state.project.statusLoading);

  const createData = useSelector((state) => state.form.createData);

  const [openOffice, setOpenOffice] = useState(false);
  const [openSource, setOpenSource] = useState(false);

  const [openLead, setOpenLead] = useState(false);
  const officeList = useSelector((state) => state.lead.leadOffice);
  const sourceList = useSelector((state) => state.lead.leadSource);
  const orgUsers = useSelector((state) => state.org.orgUsers);
  const orgUserLoading = useSelector((state) => state.org.orgUserLoading);
  const leadData = useSelector((state) => state.lead.leadData);
  const uploadedFiles = useSelector((state) => state.upload.uploadedFiles);
  const currentFile = useSelector((state) => state.upload.currentFile);
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );
  const ITEM_HEIGHT = 48;
  const [officeSelected, setOfficeSelected] = useState(
    leadSelected?.office ?? null
  );
  const [sourceSelected, setSourceSelected] = useState(
    leadSelected?.source ?? null
  );
  const [description, setDescription] = useState(null);
  const [content, setContent] = useState(
    projectData?.description ? `<p>${projectData?.description}</p>` : null
  );

  const [setter, setSetter] = useState(null);
  const [salesRep, setSalesRep] = useState(null);
  const [manager, setManager] = useState(null);

  const [isPurchaseOrderFile, setIsPurchaseOrderFile] = useState([]);
  const [progress, setProgress] = useState(0);
  const uploadLoading = useSelector((state) => state.upload.loading);
  const [addressDetails, setAddressDetails] = useState(null);

  // const [groupSelected, setGroupSelected] = useState(null);
  // const [userSelected, setUserSelected] = useState(null);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { dirtyFields } = useFormState({
    control,
  });

  const handleAssign = (event) => {
    event.preventDefault();
    setAnchorElUser(event.currentTarget);
  };

  const handleStatus = (event) => {
    event.preventDefault();
    setAnchorElStatus(event.currentTarget);
  };

  useEffect(() => {
    if (anchorElUser) {
      dispatch(
        getOrgUserList({
          url: `crm/user/organization/${orgId}/users`,
          params: {},
        })
      );
    }
  }, [anchorElUser]);

  useEffect(() => {
    if (anchorElStatus) {
      dispatch(
        getProjectStatusList({
          url: `crm/org/${orgId}/proj/status`,
          params: {},
        })
      );
    }
  }, [anchorElStatus]);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseStatusMenu = () => {
    setAnchorElStatus(null);
  };

  const onSubmit = (data) => {
    console.log("data=----------->", data);

    const editedData = dirtyValues(dirtyFields, data);

    if (dirtyFields?.customerInfo?.pContact) {
      editedData.customerInfo.pContact.id = data.customerInfo.pContact.id ?? 0;
    }
    if (dirtyFields?.customerInfo?.sContact) {
      editedData.customerInfo.sContact.id = data.customerInfo.sContact.id ?? 0;
    }

    if (leadSelected) {
      data.leadId = leadSelected?.id;
    }
    data.description = description;

    var now = dayjs();
    const chatId = userInfo.id.toString() + dayjs(now).unix().toString();
    data.chatId = chatId;
    data.addressDetails = addressDetails;

    console.log("editedData=----------->", editedData);
    if (id) {
      editedData.description = description;
      dispatch(
        editProject({
          url: `crm/org/${orgId}/proj/${id}`,
          data: editedData,
          params: {},
        })
      );
    } else {
      dispatch(
        createNewProject({
          url: `crm/org/${orgId}/proj`,
          data: data,
          params: {},
        })
      );
    }
  };

  const handlePhoneNumber = (phoneNumber, type) => {
    setValue(`customerInfo.${type}.mobile`, phoneNumber);
  };

  const onErrors = (errors) => {
    console.log("errors=---->", errors);
  };

  useEffect(() => {
    if (openSource) {
      dispatch(
        getLeadSource({
          url: `crm/org/${orgId}/leads/source`,
          params: {},
        })
      );
    }
  }, [openSource]);

  useEffect(() => {
    if (openOffice) {
      dispatch(
        getLeadOffice({
          url: `crm/org/${orgId}/leads/offices`,
          params: {},
        })
      );
    }
  }, [openOffice]);

  useEffect(() => {
    if (openLead) {
      dispatch(
        getLeadList({
          url: `crm/org/${orgId}/leads`,
          params: {},
        })
      );
    }
  }, [openLead]);

  useEffect(() => {
    if (projectData && projectData?.leadId !== null) {
      dispatch(
        getLead({
          url: `crm/org/${orgId}/leads/${projectData?.leadId}`,
          params: {},
        })
      );
      if (leadData) {
        setLeadSelected(leadData);
      }
    }

    if (projectData?.address) {
      setDesc(projectData?.address);
      setValue("address", projectData?.address);
    }

    setValue(
      "customerInfo.pContact",
      projectData?.customerInfo?.pContact ?? {}
    );
    if (projectData?.customerInfo?.sContact) {
      setValue(
        "customerInfo.sContact",
        projectData?.customerInfo?.sContact ?? {}
      );
      setShowSecondary(true);
      setBtnDisabled(true);
    }

    if (projectData?.office) {
      setOfficeSelected(projectData?.office);
      setValue("office", projectData?.office);
    }

    if (projectData?.source) {
      setSourceSelected(projectData?.source);
      setValue("source", projectData?.source);
    }

    if (projectData?.assignedto) {
      setAssignee(projectData?.assignedto?.name);
      setValue("assignedTo", {
        userId: projectData?.assignedto?.userId,
        name: projectData?.assignedto?.name,
        uid: projectData?.assignedto?.uid,
      });
    }

    if (projectData?.status) {
      setStatus(
        Array.isArray(projectData?.status)
          ? projectData?.status[0]?.statusName
          : projectData?.status?.name
      );
      setValue(
        "statusId",
        Array.isArray(projectData?.status)
          ? projectData?.status[0]?.statusId
          : projectData?.status?.id
      );
    }

    if (projectData?.createdAt) {
      setCreatedDate(dayjs(projectData?.createdAt).format("MM/DD/YYYY"));
    }

    if (projectData?.dueDate) {
      setDueDate(projectData?.dueDate);
    }

    // if (projectData?.salesRep) {
    //   setGroupSelected({ name: projectData?.salesRep?.groupName, id: projectData?.salesRep?.groupId })
    //   setUserSelected({ name: projectData?.salesRep?.name, id: projectData?.salesRep?.id , uid: projectData?.salesRep?.uid})
    // }
  }, [projectData]);

  useEffect(() => {
    if (leadSelected?.customerInfo?.pContact) {
      setValue(
        "customerInfo.pContact",
        leadSelected?.customerInfo?.pContact ?? {}
      );
    }

    if (leadSelected?.customerInfo?.sContact) {
      setValue(
        "customerInfo.sContact",
        leadSelected?.customerInfo?.sContact ?? {}
      );
      setShowSecondary(true);
      setBtnDisabled(true);
    }

    if (leadSelected?.office) {
      setOfficeSelected(leadSelected?.office);
      setValue("office", leadSelected?.office);
    }

    if (leadSelected?.source) {
      setSourceSelected(leadSelected?.source);
      setValue("source", leadSelected?.source);
    }

    if (leadSelected?.address) {
      setDesc(leadSelected?.address);
      setValue("address", leadSelected?.address);
    }
    // if (leadSelected?.salesRep) {
    //   setGroupSelected({
    //     name: leadSelected?.salesRep?.groupName,
    //     id: leadSelected?.salesRep?.groupId,
    //   });
    //   setUserSelected({
    //     name: leadSelected?.salesRep?.name,
    //     id: leadSelected?.salesRep?.id,
    //     uid: leadSelected?.salesRep?.uid,
    //   });
    // }
  }, [leadSelected]);

  const handleSelect = (address, addressValue) => {
    setValue("address", address);
    clearErrors("address");
    setAddressDetails(addressValue);
  };

  const handleGroup = (value, type) => {
    if (type === "setter") {
      setSetter(value);
      setValue("setter", value, { shouldDirty: true });
    } else if (type === "sale") {
      setSalesRep(value);
      setValue("salesRep", value, { shouldDirty: true });
    } else if (type === "manager") {
      setManager(value);
      setValue("manager", value, { shouldDirty: true });
    }
  };

  const handleDescription = (content, delta, source, editor) => {
    setContent(content);
    const descValue = editor.getText(content).replace(/\n/g, " ");

    setDescription(descValue);
  };

  const handleClose = () => {
    if (currentFile && currentFile?.length > 0) {
      const deletedUrl = currentFile?.map((file) => {
        return {
          url: file.url,
        };
      });
      dispatch(
        deleteFile({
          url: `crm/org/${orgId}/files/delete`,
          data: deletedUrl,
          params: {},
        })
      );
    }
    onClose();
  };

  const fileDelete = (name) => {
    if (currentFile && currentFile?.length > 0) {
      const deletedUrl = currentFile
        .filter((file, index) => file.name === name)
        .map((item) => {
          return { url: item.url };
        });
      dispatch(
        deleteFile({
          url: `crm/org/${orgId}/files/delete`,
          data: deletedUrl,
          params: {},
        })
      );
    }
  };

  const handleIsPurchaseOrder = (filename) => {
    setIsPurchaseOrderFile(filename);
  };

  const handleUploadProgresss = (value) => {
    setProgress(Math.round((100 * value.loaded) / value.total));
  };

  const handleFile = (files) => {
    if (files[0]) {
      // const data = { file: files[0] };
      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`files`, file);
      });

      dispatch(
        fileUpload({
          url: `crm/org/${orgId}/files/upload`,
          data: formData,
          params: {},
          onUploadProgress: handleUploadProgresss,
        })
      );
    }
  };

  useEffect(() => {
    if (createData?.createSuccess) {
      onClose();
    }
  }, [createData]);

  useEffect(() => {
    setValue("dueDate", dueDate);
  }, [dueDate]);

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">{t("New Project")}</p>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit, onErrors)}
      >
        <div className="modal_body">
          <p className="new_project_name">{t("Select a Lead")}</p>
          <AutoCompleteAsync
            sx={{ width: "100%" }}
            className="user_access_level"
            name="leadId"
            open={openLead}
            onOpen={() => {
              setOpenLead(true);
            }}
            onClose={() => {
              setOpenLead(false);
            }}
            options={leadList}
            loading={leadLoading}
            onChange={(event, newValue, reason) => {
              setLeadSelected(newValue);

              // setDesc(newValue?.address);
              // setValue("address", newValue?.address);
              if (reason === "clear") {
                setDesc("");
                setLeadSelected({});
                setValue("customerInfo.pContact", {
                  firstName: "",
                  lastName: "",
                  email: "",
                  mobile: "",
                });
                setValue("customerInfo.sContact", {
                  firstName: "",
                  lastName: "",
                  email: "",
                  mobile: "",
                });
              }
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <Box style={{ fontSize: 13 }} {...props} key={option?.id}>
                <div>
                  <Typography style={{ fontWeight: "600" }}>
                    {option?.address}
                  </Typography>
                  <Typography>
                    {t("Created Date")}:{" "}
                    {dayjs(option?.createdAt).format("MM/DD/YYYY")}
                  </Typography>
                </div>
              </Box>
            )}
            value={leadSelected}
            getOptionLabel={(option) => {
              if (option?.address) {
                return (
                  option?.address +
                  "                                      " +
                  "Created Date:" +
                  dayjs(option?.createdAt).format("MM/DD/YYYY")
                );
              } else {
                return "Please Select";
              }
            }}
            placeholder="Please select"
            register={register}
          />
          <h3 className="text_center text_dark_gray hr_lines_common">Or</h3>
          <p className="error_message">{errors.address?.message}</p>
          <p className="new_project_name">{t("Select Address")}</p>

          <PlacesAutoComplete
            desc={desc}
            innerRef={register("address", { required: true })}
            onSelect={handleSelect}
          />
          <ContactForm
            value={
              projectData?.leadId !== null
                ? leadSelected?.customerInfo?.pContact
                : projectData?.customerInfo?.pContact
            }
            register={register}
            errors={errors?.customerInfo?.pContact}
            showSecondary={showSecondary}
            btnDisabled={btnDisabled}
            type="pContact"
            onClick={(e) => {
              e.preventDefault();
              setShowSecondary(!showSecondary);
            }}
            onPhoneNumberChange={handlePhoneNumber}
          />
          {showSecondary && (
            <ContactForm
              value={
                projectData?.leadId !== null
                  ? leadSelected?.customerInfo?.pContact
                  : projectData?.customerInfo?.pContact
              }
              register={register}
              errors={errors}
              type="sContact"
              onPhoneNumberChange={handlePhoneNumber}
            />
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h4 className="mb_0">{t("Sales Information")}</h4>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name="office"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <AutoCompleteAsync
                    {...field}
                    sx={{ width: "100%", marginBottom: "10px" }}
                    name="office"
                    label={t("Office")}
                    open={openOffice}
                    onOpen={() => {
                      setOpenOffice(true);
                    }}
                    onClose={() => {
                      setOpenOffice(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value.id
                    }
                    loading={leadLoading}
                    options={officeList}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setOfficeSelected(null);
                      } else {
                        setOfficeSelected(newValue);
                        setValue("office", newValue);
                      }
                    }}
                    // register={register}
                    renderOption={(props, option) => (
                      <Box style={{ fontSize: 13 }} {...props}>
                        {option.name}
                      </Box>
                    )}
                    value={officeSelected}
                    getOptionLabel={(option) => {
                      if (option.name) {
                        return option?.name;
                      } else {
                        return t("Please Select");
                      }
                    }}
                  />
                )}
              />
              <AutoCompleteGroup
                name="salesRep"
                type="sale"
                label={t("Sales Rep")}
                onChange={handleGroup}
                value={
                  projectData?.leadId !== null
                    ? leadSelected?.salesRep
                    : projectData?.salesRep
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name="source"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <AutoCompleteAsync
                    {...field}
                    sx={{ width: "100%", marginBottom: "10px" }}
                    name="source"
                    label={t("Source")}
                    open={openSource}
                    onOpen={() => {
                      setOpenSource(true);
                    }}
                    onClose={() => {
                      setOpenSource(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value.id
                    }
                    loading={leadLoading}
                    options={sourceList}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setSourceSelected(null);
                      } else {
                        setSourceSelected(newValue);
                        setValue("source", newValue, { shouldDirty: true });
                      }
                    }}
                    // register={register}
                    renderOption={(props, option) => (
                      <Box style={{ fontSize: 13 }} {...props}>
                        {option.name}
                      </Box>
                    )}
                    value={sourceSelected}
                    getOptionLabel={(option) => {
                      if (option.name) {
                        return option?.name;
                      } else {
                        return t("Please Select");
                      }
                    }}
                  />
                )}
              />
              <AutoCompleteGroup
                name="setter"
                type="setter"
                label={t("Setter")}
                // register={register}
                onChange={handleGroup}
                value={
                  projectData?.leadId !== null
                    ? leadSelected?.setter
                    : projectData?.setter
                }
              />
            </Grid>
          </Grid>
          <div className="project_assign_details">
            <div
              style={{
                padding: "12px 16px",
                borderBottom: "1px solid #EBEBEB",
              }}
              className="d_flex align_items_center justify_content_between"
            >
              <div className="w_100">
                {/* <p className="assign_details_title">Details</p> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <AutoCompleteGroup
                      name="manager"
                      type="manager"
                      label={t("Project Manager")}
                      onChange={handleGroup}
                      value={
                        projectData?.leadId !== null
                          ? leadSelected?.manager
                          : projectData?.manager
                      }
                      horizontal={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {/* <label className="text_medium_bold">External Id</label>
                    <Controller
                      name={`externalId`}
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          // variant="mui"
                          name="externalId"
                          // label="External Id"
                          inputClass="input_field_style"
                          register={register}
                          // placeholder="External Id"
                          // register={{
                          //   ...register(`itemdetails.${index}.itemqty`, {
                          //     required: true,
                          //   }),
                          // }}
                          // fieldSize="medium"
                          marginBottom="0px"
                          // fieldStyle="standard"
                          autoFocus={false}
                        />
                      )}
                    /> */}
                  </Grid>
                </Grid>
              </div>

              {/* <img
                style={{ width: "20px", height: "20px" }}
                src={ChevronDown}
                alt=""
              /> */}
            </div>

            <div style={{ padding: "24px 16px" }}>
              <p className="assign_details_title mt_0">{t("Details")}</p>

              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <ProjectAssignDetails
                    name="assignee"
                    label={t("ASSIGNED TO")}
                    icon={AssignToIcon}
                    value={assignee ?? "No assignee"}
                    onClick={handleAssign}
                    errorMessage={errors?.assignedTo?.message}
                  />
                  {!orgUserLoading && (
                    <Menu
                      sx={{ mt: "30px" }}
                      style={{ height: "400px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {orgUsers?.map((user, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            setAssignee(user?.name);
                            setValue(
                              "assignedTo",
                              {
                                userId: user?.userId,
                                name: user?.name,
                                uid: user?.uid,
                              },
                              { shouldDirty: true }
                            );
                            handleCloseUserMenu();
                          }}
                          {...register("assignedTo", { required: true })}
                        >
                          <IconButton sx={{ p: 0, marginRight: "8px" }}>
                            <Avatar
                              style={{ width: "30px", height: "30px" }}
                              {...stringAvatar(user?.name)}
                            />
                          </IconButton>
                          <Typography textAlign="center">
                            {user?.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </Grid>
                {id ? null : (
                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <ProjectAssignDetails
                      name="statusId"
                      label={t("STATUS")}
                      icon={AssignToIcon}
                      value={status ?? "No Status"}
                      onClick={handleStatus}
                      errorMessage={errors?.statusId?.message}
                    />

                    {!statusLoading && (
                      <Menu
                        sx={{ mt: "30px" }}
                        style={{ height: "400px" }}
                        id="menu-appbar"
                        anchorEl={anchorElStatus}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElStatus)}
                        onClose={handleCloseStatusMenu}
                      >
                        {projectStatusList?.map((status, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setStatus(status?.displayName);
                              setValue("statusId", status?.id, {
                                shouldDirty: true,
                              });
                              handleCloseStatusMenu();
                            }}
                            {...register("statusId", { required: true })}
                          >
                            {/* <IconButton sx={{ p: 0, marginRight: "8px" }}>
                          <Avatar
                            style={{ width: "30px", height: "30px" }}
                            {...stringAvatar(user?.name)}
                          />
                        </IconButton> */}
                            <Typography textAlign="center">
                              {status?.displayName}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </Grid>
                )}
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <ProjectAssignDetails
                    label={t("CREATED")}
                    icon={CreateDateIcon}
                    value={createdDate}
                  />
                </Grid>

                {/* <ProjectAssignDetails label="LABELS" value="Not defined yet" /> */}
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  <ProjectAssignDetails
                    label={t("DUE DATE")}
                    icon={DueDateIcon}
                    // value={dayjs(dueDate).format("MM/DD/YYYY")}
                    type="date"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenDate(true);
                    }}
                    openDate={openDate}
                    onDateChange={(date, e) => {
                      e.preventDefault();
                      setOpenDate(false);
                      setDueDate(date);
                      setValue("dueDate", dueDate, { shouldDirty: true });
                    }}
                    date={dueDate}
                    // {...register("dueDate", {})}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            style={{ marginTop: "32px", position: "relative", height: "250px" }}
          >
            <TextEditor
              name="description"
              label={t("Description")}
              value={content}
              onChange={handleDescription}
            />
          </div>
          {/* {id ? null : (
            <div style={{ marginTop: "32px", height: "300px" }}>
              <DragDropUploader
                onChange={handleFile}
                onDelete={fileDelete}
                onIsPurchseOrder={handleIsPurchaseOrder}
                showCheckbox={true}
                progress={progress}
              />
            </div>
          )} */}
        </div>
        <div className="modal_footer">
          <Button type="submit" className="primary_button">
            {t("Save")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ProjectForm;
