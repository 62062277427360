import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardItem } from "../../../utils/CardItem";
import ToolBar from "../../../components/toolbar/ToolBar";
import { getProjectStatusList } from "../../../redux/slices/projectSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/loading/Loading";
import MovingIcon from "@mui/icons-material/Moving";
import { useForm, useFieldArray } from "react-hook-form";
import { Grid } from "@mui/material";
import "./style.scss";
import Button from "../../../components/buttons/Button";
import { createProjectStatusOrg } from "../../../redux/slices/orgSlice";

const Webhook = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const statusLoading = useSelector((state) => state.project.statusLoading);
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );

  const { control, handleSubmit, register, reset, formState: { errors } } = useForm({
    defaultValues: {
      statuses: [], // Initialize empty array for statuses
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "statuses", // Bind statuses to useFieldArray
  });

  const editStatus = (data) => {
    // let status = [
    //   {
    //     ...itemToEdit,
    //     isactive: true,
    //     name: data.name,
    //     url: data.url,
    //     displayName: data.displayName ? data.displayName : data.name,
    //     totalDays: Number(data.totalDays),
    //   },
    // ];

    dispatch(
      createProjectStatusOrg({
        url: `/crm/user/organization/${id}/status`,
        params: {
          orgId: id,
        },
        data: {
          status: data.statuses,
        },
      })
    );
  };

  // Load project status list
  const loadStatusList = () => {
    dispatch(
      getProjectStatusList({ url: `crm/org/${id}/proj/status`, params: {} })
    );
  };
  console.log("projectstatus list", projectStatusList);
  // Populate form with project status list
  useEffect(() => {
    if (projectStatusList.length > 0) {
      reset({
        statuses: projectStatusList.map((status) => ({
          ...status,
          id: status.id,
          isactive: true,
          displayName: status.displayName || t("Unnamed Status"),
          url: status.url, // Map `url` directly
        })),
      });
    }
  }, [projectStatusList, reset, t]);

  useEffect(() => {
    loadStatusList();
  }, []);

  const onSubmit = (data) => {
    console.log("Submitted Data: ", data);
    // Dispatch an action or make an API call to save the data
  };

  return (
    <CardItem height="890px">
      <div className="card_container">
        <div style={{ padding: "20px" }}>
          <ToolBar title={t("Webhook")} />
        </div>
        {statusLoading ? (
          <Loading />
        ) : (
          <>
            <form
              onSubmit={handleSubmit(editStatus)}
              style={{ padding: "20px" }}
            >
              <div style={{ height: "650px", overflowY: "scroll" }}>
                <div className="org_list_wrapper">
                  {/* {statusLoading ? (
                <Loading />
              ) : */}
                  {fields.length > 0 ? (
                    fields.map((field, index) => (
                      <div key={field.id} className="light_bg_border">
                        <div className="d_flex justify_content_between w_100">
                          <div className="w_100">
                            <div className="d_flex align_items_center">
                              <MovingIcon
                                style={{ color: "#189d3d" }}
                                className="org_icon"
                              />
                              <div>
                                <h4 className="my_0">{field.displayName}</h4>
                              </div>
                            </div>
                            <div className="d_flex align_items_center w_100">
                              <Grid item xs={12}>
                                <input
                                  style={{
                                    width: "80%",
                                    borderRadius: "5px",
                                    border: "1px solid #cdd2e1",
                                    padding: "10px",
                                    height: "22px",
                                  }}
                                  type="text"
                                  //placeholder={t("Enter URL")}
                                  {...register(`statuses.${index}.url`, {
                                    pattern: {
                                      value:
                                        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                                      message: "Please enter a valid URL", // Custom error message
                                    },
                                  })} // Use `url` here
                                  defaultValue={field.url} // Display initial `url` value
                                />{" "}
                                <p className="error_message">
                                  {errors.name?.message}
                                </p>
                                {errors?.statuses?.[index]?.url && (
                                  <p className="error_message">
                                    {errors?.statuses?.[index]?.url?.message}
                                  </p>
                                )}
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>{t("No statuses available.")}</div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button type="submit" className="primary_button marginT10">
                  {t("Save")}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </CardItem>
  );
};

export default Webhook;
