import { Grid } from "@mui/material";
import React, { useState } from "react";
import CustomSearch from "../../../components/searchbar/CustomSearch";
import ToolBar from "../../../components/toolbar/ToolBar";
import { CardItem } from "../../../utils/CardItem";
import RoleTablet from "./RoleTablet";
import { useTranslation } from "react-i18next";

const AssignRoles2 = () => {
  const {t}=useTranslation();
  const [searchValue, setSearchValue] = useState(null);
  return (
    <CardItem height="890px">
      <div className="card_container">
        <div style={{ padding: "20px" }}>
          <ToolBar title={"Roles"}>
            <div className="d_flex align_items_center justify_content_between">
              <CustomSearch
                placeholder={"Search"}
                onChange={(e) => {
                  let value = e.target.value.trim();
                  if (value == "") {
                    setSearchValue(null);
                  } else {
                    setSearchValue(e.target.value);
                  }
                }}
              />
            </div>
          </ToolBar>
        </div>
        <div style={{ padding: "20px" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h3>{t("Users")}</h3>
                  </div>
                  <div>
                    {" "}
                    {/* <Button
                      type="submit"
                      className="primary_button d_flex align_items_center"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "18px",
                        padding: "5px 20px",
                      }}
                      leftIcon={<PlusIcon color="#fff" />}
                      onClick={() => {}}
                    >
                      Add User
                    </Button> */}
                    
                  </div>
                </div>
                <div >
                  <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  </div>
                
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
              <div >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h3>Groups</h3>
                  </div>
                  <div>
                    {" "}
                    {/* <Button
                      type="submit"
                      className="primary_button d_flex align_items_center"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "18px",
                        padding: "5px 20px",
                      }}
                      leftIcon={<PlusIcon color="#fff" />}
                      onClick={() => {}}
                    >
                      Add User
                    </Button> */}
                  </div>
                </div>
                <div >
                  <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  <RoleTablet title={"test"}/>
                  </div>
                
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </CardItem>
  );
};

export default AssignRoles2;
