import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import TaskIcon from "@mui/icons-material/Task";
import CancelIcon from "@mui/icons-material/Cancel";
import CardTitle from "../../../dashboard/CardTitle";
import Button from "../../../buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import {
  CustomTabPanel,
  StyledTab,
  a11yProps,
  formatEvents,
} from "../../../constants/Constants";
import BasicModal from "../../../modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import ComingSoon from "../../../common/ComingSoon";
import AppointmentForm from "./AppointmentForm";
import { getAppointmentList } from "../../../../redux/slices/appointmentSlice";
import { useParams } from "react-router-dom";
import { modalStyle } from "../../../constants/ConstData";
import AppointmentListItem from "./AppointmentListItem";
import { setCreateSuccess } from "../../../../redux/slices/formSlice";
import Storage from "../../../../utils/Storage";
import { useTranslation } from "react-i18next";

export default function ProjectAppointments({ type }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const projectData = useSelector((state) => state.project.projectData);
  const dispatch = useDispatch();
  const createData = useSelector((state) => state.form.createData);
  const [tabValue, setTabValue] = React.useState(0);
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const { t } = useTranslation();
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;

  const { id } = useParams();

  const loadAppointmentList = () => {
    dispatch(
      getAppointmentList({
        url: `crm/org/${orgId}/${type}/${id}/appointment/status/${tabValue}`,
        params: {},
      })
    );
  };

  const appointmentTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const newAppointment = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    console.log("isOpen=--------->", isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    if (snackbarData?.createSuccess) {
      loadAppointmentList();
      handleClose();
    }
  }, [snackbarData]);

  React.useEffect(() => {
    if (orgId) {
      loadAppointmentList();
    }
  }, [tabValue, orgId]);

  return (
    <div className="card_container">
      <CardTitle title={t("Appointments")} type="project_view">
        <Button
          onClick={newAppointment}
          className="icon_button d_flex align_items_center primary_bg text_white"
        >
          <AddIcon className="btn_icon" />
          {t("Add New")}
        </Button>
      </CardTitle>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          // textColor="secondary"
          // indicatorColor="secondary"

          TabIndicatorProps={{
            style: {
              backgroundColor: "#189d3d",
            },
          }}
          value={tabValue}
          onChange={appointmentTabChange}
          aria-label="basic tabs example"
        >
          <StyledTab
            icon={<UpcomingIcon />}
            iconPosition="start"
            label={t("Scheduled")}
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(0)}
          />
          <StyledTab
            icon={<TaskIcon />}
            iconPosition="start"
            label={t("Completed")}
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(1)}
          />
          <StyledTab
            icon={<CancelIcon />}
            iconPosition="start"
            label="Cancelled"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <div className="card_content">
        <CustomTabPanel value={tabValue} index={0}>
          <AppointmentListItem code={tabValue} type={type} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <AppointmentListItem code={tabValue} type={type} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <AppointmentListItem code={tabValue} type={type} />
        </CustomTabPanel>
      </div>
      <BasicModal
        isOpen={isOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        {isOpen && <AppointmentForm type={type} onClose={handleClose} />}
      </BasicModal>
    </div>
  );
}
