import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";

export default function CustomAutoComplete({
  name,
  label,
  options,
  variant,
  className,
  placeholder,
  sx,
  // register,
  renderOption,
  getOptionLabel,
  value,
  onChange,
  loading,
  open,
  onOpen,
  onClose,
  isOptionEqualToValue,
  noOptionsText,
  fieldSize,
  disabled,
  multiple,
  inputValue,
  onInputChange,
  onBlur,
  width,
  required,
}) {
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "red",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        className={className ?? ""}
        sx={
          sx
            ? {
                ...sx,
                "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "2px solid #189d3d",
                  },
                "&.Mui-focused .MuiInputLabel-outlined": {
                  color: "#189d3d",
                },
              }
            : {
                width: width ? width : 300,
                marginTop: "8px",
                marginBottom: "10px",
                "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "2px solid #189d3d",
                  },
                "&.Mui-focused .MuiInputLabel-outlined": {
                  color: "#189d3d",
                },
              }
        }
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        value={value}
        disabled={disabled ? true : false}
        disablePortal
        id={`combo-box-demo-${name}`}
        options={options}
        loading={loading}
        onChange={onChange}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            InputProps={{
              ...params.InputProps,
              // endAdornment: (
              //   <React.Fragment>
              //     {loading ? (
              //       <CircularProgress color="inherit" size={20} />
              //     ) : null}
              //     {params.InputProps.endAdornment}
              //   </React.Fragment>
              // ),
              style: { fontSize: 13 },
            }}
            label={label}
            variant={variant}
            placeholder={placeholder}
            size={fieldSize ?? null}
            // {...register(name, {})}
          />
        )}
        multiple={multiple ? true : false}
        // {...register(name, {})}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onBlur={onBlur}
      />
    </ThemeProvider>
  );
}
