import React, { useEffect, useState } from "react";
import { CardItem } from "../../../utils/CardItem";
import ToolBar from "../../../components/toolbar/ToolBar";
import DataTable from "../../../components/table/DataTable";
import CustomSearch from "../../../components/searchbar/CustomSearch";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getUserListOrg,
  makeUserAsModerator,
} from "../../../redux/slices/orgSlice";
import { useParams } from "react-router-dom";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Loading from "../../../components/loading/Loading";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { useTranslation } from "react-i18next";

const Users = () => {
  const { t } = useTranslation();
  const [userToSwitch, setUserToSwitch] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [
    openSwitchUserToModeratorConfirmation,
    setSwitchUserToModeratorConfirmation,
  ] = useState(false);
  const teamColumns = [
    {
      field: "slno",
      headerName: t("Sl No."),
      width: 80,
    },
    {
      field: "id",
      headerName: t("User Id"),
      width: 80,
    },
    {
      field: "name",
      headerName: t("Name"),
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },
    {
      field: "email",
      headerName: t("Email"),
      width: 300,
      renderCell: (params) => {
        return <Typography>{params?.row?.email}</Typography>;
      },
    },
    {
      field: "user_type",
      headerName: t("User Type"),
      width: 150,
      renderCell: (params) => {
        let userType = "User";
        if (params.row.isowner === "true") userType = "Owner";
        else if (params?.row?.isModerator == "true") userType = "Moderator";
        return <Typography>{userType}</Typography>;
      },
    },
    {
      width: 100,
      renderCell: (params) => {
        if (params.row.isOwner === "true") return;
        const handleEditClick = (e) => {};
        if (
          params.row.isOwner === "false" &&
          params.row.isModerator === "false"
        ) {
          return (
            <div>
              <ThreeDotDropdown
                options={[{ name: "Make Moderator", value: "switchUser" }]}
                onSelect={(value) => {
                  setUserToSwitch(params.row);
                  setOpenConfirmation(true);
                }}
              />
            </div>
          );
        }
        if (
          params.row.isOwner === "false" &&
          params.row.isModerator === "true"
        ) {
          return (
            <div>
              <ThreeDotDropdown
                options={[{ name: "Make as User", value: "switchUser" }]}
                onSelect={(value) => {
                  console.log("value", params.row);
                  setUserToSwitch(params.row);
                  setSwitchUserToModeratorConfirmation(true);
                }}
              />
            </div>
          );
        }
      },
    },
  ];
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState(null);
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.org.userListOrg);
  const userListLoading = useSelector((state) => state.org.userListLoading);
  const createData = useSelector((state) => state.form.createData);
  const snackbarData = useSelector((state) => state.form.snackbarData);

  const switchUser = () => {
    dispatch(
      makeUserAsModerator({
        url: `/crm/org/${id}/map/userRoles`,
        data: [
          {
            userId: userToSwitch.userId,
            isActive: openConfirmation ? 1 : 0,
          },
        ],
      })
    );
  };
  const loadUsers = () => {
    dispatch(
      getUserListOrg({
        url: `/crm/user/organization/${id}/users`,
      })
    );
  };
  useEffect(() => {
    if (snackbarData?.createSuccess) {
      setOpenConfirmation(false);
      setSwitchUserToModeratorConfirmation(false);
    }
    loadUsers();
  }, [snackbarData]);
  return (
    <>
      <CardItem height="890px">
        {userListLoading ? (
          <Loading />
        ) : (
          <div className="card_container">
            <CustomDialog
              open={openConfirmation}
              title="Give Moderator previleges"
              message={`Are you sure you want to give the user "${userToSwitch?.name}" moderator previleges?`}
              okText="Grant Permission"
              cancelText="cancel"
              onOk={switchUser}
              onCancel={() => {
                setUserToSwitch(null);
                setOpenConfirmation(false);
              }}
            />
            <CustomDialog
              open={openSwitchUserToModeratorConfirmation}
              title="Make as regular user"
              message={`Are you sure you want to give the Moderator "${userToSwitch?.name}" regular user previleges?`}
              okText="Grant Permission"
              cancelText="cancel"
              onOk={switchUser}
              onCancel={() => {
                setUserToSwitch(null);
                setSwitchUserToModeratorConfirmation(false);
              }}
            />
            {/* <CardTitle title={selectedLink} type={"project_view"}></CardTitle> */}
            <div style={{ padding: "20px" }}>
              <ToolBar title={t("Users")}>
                <div className="d_flex align_items_center justify_content_between">
                  <CustomSearch
                    placeholder={t("Search")}
                    onChange={(e) => {
                      let value = e.target.value.trim();
                      if (value == "") {
                        setSearchValue(null);
                      } else {
                        setSearchValue(e.target.value);
                      }
                    }}
                  />
                </div>
              </ToolBar>
            </div>

            <div style={{ padding: "20px" }}>
              <DataTable
                rows={
                  searchValue
                    ? [
                        ...userList
                          .filter((item, index) => {
                            return (
                              item?.name
                                ?.toLowerCase()
                                ?.includes(searchValue?.toLowerCase()) ||
                              item?.email
                                ?.toLowerCase()
                                ?.includes(searchValue?.toLowerCase())
                            );
                          })
                          .map((item, index) => {
                            return {
                              ...item,
                              id: item.userId,
                              slno: index + 1,
                            };
                          }),
                      ]
                    : [
                        ...userList.map((item, index) => {
                          return {
                            ...item,
                            id: item.userId,
                            slno: index + 1,
                          };
                        }),
                      ]
                }
                columns={teamColumns}
              />
            </div>
          </div>
        )}
      </CardItem>
    </>
  );
};

export default Users;
