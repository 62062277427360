import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

const CountCard = ({ title, countdtls, icon, type }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (countdtls?.length > 0) {
      var result = countdtls.reduce(function (acc, obj) {
        return acc + obj[type];
      }, 0);
      setCount(result);
    }
  }, [countdtls]);

  return (
    <div
      style={{ color: "#fff", padding: "10px" }}
      className="d_flex justify_content_between align_items_center"
    >
      <div>
        {icon}
        <div>
          <Typography sx={{ textTransform: "uppercase" }} variant="p">
            {title}
          </Typography>
        </div>
      </div>
      <div>
        <Typography variant="p">{count}</Typography>
      </div>
    </div>
  );
};

export default CountCard;
