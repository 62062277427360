import { Box, styled } from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ padding: 0, width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#189d3d",
  },
});

export const formatEvents = (list) => {
  return list.map((item) => ({
    title: item.subject,
    start: new Date(Date.parse(item.startDate)),
    end: new Date(Date.parse(item.endDate)),
    description: item.description,
    location: item.location,
    invitees: item.invitees,
    allDay: item.fullDay,
    organizerEmail: item.organizerEmail,
    organizerName: item.organizerName,
    projectId: item.projectId,
    appointmentId: item.appointmentId,
    isOnline: item.isOnline,
    isTask: item.isTask,
  }));
};

export const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, height, progressColor, backgroundColor }) => ({
    height: height ?? 10,
    width: "100%",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor ?? theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: backgroundColor ?? theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: progressColor ?? "#1a90ff",
      ...theme.applyStyles("dark", {
        backgroundColor: progressColor ?? "#308fe8",
      }),
    },
  })
);
