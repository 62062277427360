import { Box, Grid, IconButton } from "@mui/material";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import ModalBody from "../../../modal/ModalBody";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../../../fields/text/TextInput";
import { useEffect, useState } from "react";
import ModalFooter from "../../../modal/ModalFooter";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createAdditionalInfo,
  createFinancialInfo,
  getFinSource,
  getFinType,
} from "../../../../redux/slices/additionalInfoSlice";
import AutoCompleteAsync from "../../../../fields/autoComplete/AutoCompleteAsync";
import { useTranslation } from "react-i18next";

const FinancialDetailsEditForm = ({ onClose, editItem }) => {
  const { t } = useTranslation();
  const finTypeList = useSelector((state) => state.additionalInfo.finTypeList);

  const finTypeLoading = useSelector(
    (state) => state.additionalInfo.finTypeLoading
  );
  const finSourceList = useSelector(
    (state) => state.additionalInfo.finSourceList
  );

  const finSourceLoading = useSelector(
    (state) => state.additionalInfo.finSourceLoading
  );
  const [openFinType, setOpenFinType] = useState(false);
  const [openFinSource, setOpenFinSource] = useState(false);

  const [finTypeSelected, setFinTypeSelected] = useState({
    type: editItem?.financeType,
  });
  const [finTypeNewList, setFinTypeNewList] = useState(finTypeList ?? []);

  const [finTypeInputValue, setFinTypeInputValue] = useState("");

  const [finSourceSelected, setFinSourceSelected] = useState({
    name: editItem?.source,
  });
  const [finSourceNewList, setFinSourceNewList] = useState(finSourceList ?? []);
  const [finSourceInputValue, setFinSourceInputValue] = useState("");

  const currentOrg = useSelector((state) => state.auth.currentOrg);
  const orgId = currentOrg?.id;
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (editItem?.id) {
      data.id = editItem?.id;
    }

    const editedData = {
      financialdtl: [data],
    };

    dispatch(
      createFinancialInfo({
        url: `/crm/org/${orgId}/proj/${id}/info`,
        data: editedData,
        params: { data: "financialdtl" },
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (editItem?.financeType) {
      setValue("type", editItem?.financeType);
    }
    if (editItem?.loanAmount) {
      setValue("loanAmt", editItem?.loanAmount);
    }
    if (editItem?.source) {
      setValue("source", editItem?.source);
    }
    if (editItem?.referenceNo) {
      setValue("referenceNo", editItem?.referenceNo);
    }
    if (editItem?.comments) {
      setValue("comments", editItem?.comments);
    }
    // if (editItem?.baseppw) {
    //   setValue("baseppw", editItem?.baseppw);
    // }
    // if (editItem?.dealerFee) {
    //   setValue("dealerFee", editItem?.dealerFee);
    // }
    // if (editItem?.totalppw) {
    //   setValue("totalppw", editItem?.totalppw);
    // }
  }, [editItem]);

  useEffect(() => {
    if (openFinType) {
      dispatch(
        getFinType({
          url: `crm/org/${orgId}/finance-types`,
          params: {},
        })
      );
    }
  }, [openFinType]);

  useEffect(() => {
    if (openFinSource) {
      dispatch(
        getFinSource({
          url: `crm/org/${orgId}/finance-sources`,
          params: {},
        })
      );
    }
  }, [openFinSource]);

  useEffect(() => {
    if (finTypeList) {
      setFinTypeNewList(finTypeList);
    } else {
      setFinTypeNewList([]);
    }
  }, [finTypeList]);

  useEffect(() => {
    if (finSourceList) {
      setFinSourceNewList(finSourceList);
    } else {
      setFinSourceNewList([]);
    }
  }, [finSourceList]);

  return (
    <>
      <ModalHead title={t("Finance")}>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`type`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <AutoCompleteAsync
                    {...field}
                    required={true}
                    className="input_field_style"
                    name={`type`}
                    label={t("Finance Type")}
                    open={openFinType}
                    onOpen={() => {
                      setOpenFinType(true);
                    }}
                    onClose={() => {
                      setOpenFinType(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.type === value?.type
                    }
                    loading={finTypeLoading}
                    options={finTypeNewList}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setFinTypeSelected(null);
                      } else {
                        clearErrors(`type`);

                        setFinTypeSelected({ type: newValue.type });

                        setValue(`type`, newValue.type);
                      }
                    }}
                    renderOption={(props, option, { index }) => {
                      return (
                        <>
                          {option?.type !== "" ? (
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option.type}
                            </Box>
                          ) : (
                            <Box>{finTypeInputValue}</Box>
                          )}
                        </>
                      );
                    }}
                    value={finTypeSelected}
                    getOptionLabel={(option) => {
                      if (option?.type) {
                        return option?.type;
                      } else {
                        return t("Please Select");
                      }
                    }}
                    fieldSize="small"
                    getOptionDisabled={(option) =>
                      finTypeSelected.includes(option.id)
                    }
                    inputValue={finTypeInputValue}
                    onInputChange={(event, newInputValue) => {
                      setFinTypeInputValue(newInputValue);

                      if (newInputValue !== "") {
                        setFinTypeNewList((prevArray) => {
                          if (prevArray?.length === 0) {
                            // If the array is empty, just add the first object
                            return [{ id: 0, type: newInputValue }];
                          } else {
                            // Otherwise, replace the value of the last object
                            const updatedArray = [...prevArray];
                            updatedArray[updatedArray.length - 1] = {
                              ...updatedArray[updatedArray.length - 1],
                              id: 0,
                              type: newInputValue,
                            };
                            return updatedArray;
                          }
                        });
                      } else {
                        setFinTypeNewList(finTypeList ?? []);
                      }
                    }}
                  />
                )}
              />

              {errors && errors?.type?.message && (
                <p className="error_message">{errors?.type?.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`loanAmt`}
                control={control}
                defaultValue={null}
                rules={{
                  // required: 'This field is required',
                  pattern: {
                    value: /^\d{1,10}(\.\d{0,2})?$/,
                    message: "Enter up to 10 digits and 2 decimal places only",
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type="number"
                    variant="mui"
                    name={`loanAmt`}
                    label={t("Amount")}
                    required={true}
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                    inputProps={{
                      inputMode: "decimal", // Helps mobile users
                    }}
                  />
                )}
              />

              {errors && errors?.loanAmt?.message && (
                <p className="error_message">{errors?.loanAmt?.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`source`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <AutoCompleteAsync
                    {...field}
                    className="input_field_style"
                    name={`source`}
                    label={t("Source")}
                    open={openFinSource}
                    onOpen={() => {
                      setOpenFinSource(true);
                    }}
                    onClose={() => {
                      setOpenFinSource(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    loading={finSourceLoading}
                    options={finSourceNewList}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setFinSourceSelected(null);
                      } else {
                        clearErrors(`type`);

                        setFinSourceSelected({ name: newValue.name });

                        setValue(`source`, newValue.name);
                      }
                    }}
                    renderOption={(props, option, { index }) => {
                      return (
                        <>
                          {option?.name !== "" ? (
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option.name}
                            </Box>
                          ) : (
                            <Box>{finSourceInputValue}</Box>
                          )}
                        </>
                      );
                    }}
                    value={finSourceSelected}
                    getOptionLabel={(option) => {
                      if (option?.name) {
                        return option?.name;
                      } else {
                        return t("Please Select");
                      }
                    }}
                    fieldSize="small"
                    getOptionDisabled={(option) =>
                      finSourceSelected.includes(option.id)
                    }
                    inputValue={finSourceInputValue}
                    onInputChange={(event, newInputValue) => {
                      setFinSourceInputValue(newInputValue);

                      if (newInputValue !== "") {
                        setFinSourceNewList((prevArray) => {
                          if (prevArray?.length === 0) {
                            // If the array is empty, just add the first object
                            return [{ id: 0, name: newInputValue }];
                          } else {
                            // Otherwise, replace the value of the last object
                            const updatedArray = [...prevArray];
                            updatedArray[updatedArray.length - 1] = {
                              ...updatedArray[updatedArray.length - 1],
                              id: 0,
                              name: newInputValue,
                            };
                            return updatedArray;
                          }
                        });
                      } else {
                        setFinSourceNewList(finTypeList ?? []);
                      }
                    }}
                  />
                )}
              />

              {errors && errors?.source?.message && (
                <p className="error_message">{errors?.source?.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`referenceNo`}
                control={control}
                defaultValue={null}
                // rules={{
                //   required: "This field is required",
                //   pattern: {
                //     value: /^\d{1,10}(\.\d{0,2})?$/,
                //     message:
                //       "Enter up to 10 digits and 2 decimal places only",
                //   },
                // }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    // type="number"
                    variant="mui"
                    name={`referenceNo`}
                    label={t("Reference Number")}
                    // required={true}
                    inputClass="input_field_style"
                    fieldSize="small"
                    marginBottom="0px"
                    autoFocus={false}
                    // inputProps={{
                    //   inputMode: "decimal", // Helps mobile users
                    // }}
                  />
                )}
              />

              {errors && errors?.referenceNo?.message && (
                <p className="error_message">{errors?.referenceNo?.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name={`comments`}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    id="outlined-multiline-static"
                    variant="mui"
                    name={`comments`}
                    label={t("Comments")}
                    inputClass="input_field_style"
                    fieldSize="small"
                    marginBottom="0px"
                    autoFocus={false}
                    multiline={true}
                    rows={4}
                  />
                )}
              />
              {errors && errors?.comments?.message && (
                <p className="error_message">{errors?.comments?.message}</p>
              )}
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`baseppw`}
                control={control}
                defaultValue={null}
                rules={{
                  // required: 'This field is required',
                  pattern: {
                    value: /^\d{1,10}(\.\d{0,2})?$/,
                    message: "Enter up to 10 digits and 2 decimal places only",
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type="number"
                    variant="mui"
                    name={`baseppw`}
                    label="Base PPW"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                    inputProps={{
                      inputMode: "decimal", // Helps mobile users
                    }}
                  />
                )}
              />

              {errors && errors?.baseppw?.message && (
                <p className="error_message">{errors?.baseppw?.message}</p>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`dealerFee`}
                control={control}
                defaultValue={null}
                rules={{
                  // required: 'This field is required',
                  pattern: {
                    value: /^\d{1,10}(\.\d{0,2})?$/,
                    message: "Enter up to 10 digits and 2 decimal places only",
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type="number"
                    variant="mui"
                    name={`dealerFee`}
                    label="Dealer Fee"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                    inputProps={{
                      inputMode: "decimal", // Helps mobile users
                    }}
                  />
                )}
              />

              {errors && errors?.dealerFee?.message && (
                <p className="error_message">{errors?.dealerFee?.message}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name={`totalppw`}
                control={control}
                defaultValue={null}
                rules={{
                  // required: 'This field is required',
                  pattern: {
                    value: /^\d{1,10}(\.\d{0,2})?$/,
                    message: "Enter up to 10 digits and 2 decimal places only",
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type="number"
                    variant="mui"
                    name={`totalppw`}
                    label="Total PPW"
                    inputClass="input_field_style"
                    // placeholder="Quantity"
                    // register={{
                    //   ...register(`itemdetails.${index}.itemqty`, {
                    //     required: true,
                    //   }),
                    // }}
                    fieldSize="small"
                    marginBottom="0px"
                    // fieldStyle="standard"
                    autoFocus={false}
                    inputProps={{
                      inputMode: "decimal", // Helps mobile users
                    }}
                  />
                )}
              />

              {errors && errors?.totalppw?.message && (
                <p className="error_message">{errors?.totalppw?.message}</p>
              )}
         
            </Grid> */}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="primary_button">
            Save
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default FinancialDetailsEditForm;
