import * as React from "react";
import ModalHead from "../../../modal/ModalHead";
import ModalBody from "../../../modal/ModalBody";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "../../../../assets/images/close";
import {
  createChecklist,
  getChecklist,
  updateChecklist,
} from "../../../../redux/slices/checklistSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Storage from "../../../../utils/Storage";
import Checkbox from "../../../../fields/checkbox/Checkbox";
import { useSelector } from "react-redux";
import Button from "../../../buttons/Button";
import { CHECKLIST_TYPE } from "../../../../constants/checkListTypeConstants";
import AutoCompleteAsync from "../../../../fields/autoComplete/AutoCompleteAsync";
import TextInput from "../../../../fields/text/TextInput";
import Loading from "../../../loading/Loading";
import { useTranslation } from "react-i18next";

const CheckList = ({ selectedStatus, onClose }) => {
  const {t}=useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const checklistItem = useSelector((state) => state.checklist.checklistItem);
  const loading = useSelector((state) => state.checklist.loading);

  const [listItem, setListItem] = React.useState(
    checklistItem?.chklist_dtls ?? []
  );
  const currentOrg = useSelector((state) => state.auth.currentOrg);

  const orgId = currentOrg?.id;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openDropdown, setOpenDropdown] = React.useState({});
  const [selectedDopdownValue, setSelectedDopdownValue] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      getChecklist({
        url: `/crm/org/${orgId}/proj/${id}/checkList/${selectedStatus?.checklist[0]?.id}`,
        params: {},
      })
    );
  }, [selectedStatus]);

  const handleClose = () => {
    onClose();
    setListItem([]);
  };

  const handleAccordion = (item) => (event, isExpanded) => {
    setExpanded(isExpanded ? item?.id : false);
    dispatch(
      getChecklist({
        url: `/crm/org/${orgId}/proj/${id}/checkList/${item?.id}`,
        params: {},
      })
    );
  };

  const handleChecklist = (itemId) => {
    // const index = chklist_dtls.findIndex(item => item.id === itemId);
    // if (index !== -1) {
    //     listItem[index].value = 1;
    // }
    setListItem((prevState) =>
      prevState.map(
        (item) =>
          item.chkid === itemId
            ? { ...item, value: item.value === 1 ? 0 : 1 } // Update value for the matched item
            : item // Return other items as is
      )
    );
  };

  const handleSubmit = () => {

    const newList = listItem.map((item) => {
      return {
        id: item?.id,
        chkid: item?.chkid,
        prjid: id,
        statusid: item?.statusId,
        value: item?.value,
      };
    });
    const newData = { chklistdtl: newList };
    dispatch(
      createChecklist({
        url: `/crm/org/${orgId}/proj/${id}/checkList/${checklistItem?.id}`,
        data: newData,
        params: {},
      })
    );
  };

  React.useEffect(() => {
    setListItem(checklistItem?.chklist_dtls);
  }, [checklistItem?.chklist_dtls]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ModalHead title="Checklist">
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <ModalBody>
        <div className="card_content">
          {!loading && listItem?.length > 0 && (
            <>
              {listItem?.map((item, index) => (
                <div key={index} className="marginB10 marginT10">
                  {item.type === CHECKLIST_TYPE?.CHECKBOX && (
                    <Checkbox
                      type="checkbox"
                      name="chkidvalue"
                      label={item?.name}
                      onChange={() => handleChecklist(item?.chkid)}
                      checked={item?.value == 1}
                      required={item?.default === 1 ? true : false}
                    />
                  )}
                  {item.type === CHECKLIST_TYPE?.TEXT && (
                    <TextInput
                      variant="mui"
                      required={item?.default === 1 ? true : false}
                      name={item?.name}
                      label={item?.name}
                      inputClass="input_field_style"
                      fieldSize="small"
                      marginBottom="0px"
                      // fieldStyle="standard"
                      onChange={(e) => {
                        console.log(
                          "e.target.value=---------->",
                          e.target.value
                        );

                        setListItem((prevState) =>
                          prevState.map(
                            (list) =>
                              list.chkid === item.chkid
                                ? {
                                    ...list,
                                    value: e.target.value,
                                  } // Update value for the matched item
                                : list // Return other items as is
                          )
                        );

                        console.log("listItem=---->", listItem);
                      }}
                      value={item?.value ?? ""}
                    />
                  )}
                  {(item.type === CHECKLIST_TYPE?.DROPDOWN ||
                    item.type === CHECKLIST_TYPE?.MULTI_SELECT_DROP_DOWN) && (
                    <AutoCompleteAsync
                      multiple={
                        item.type === CHECKLIST_TYPE?.MULTI_SELECT_DROP_DOWN
                          ? true
                          : false
                      }
                      name={item.name}
                      label={item.name}
                      required={item?.default === 1 ? true : false}
                      open={
                        openDropdown?.name === item?.name && openDropdown?.value
                      }
                      onOpen={() => {
                        setOpenDropdown({ name: item?.name, value: true });
                      }}
                      onClose={() => {
                        setOpenDropdown({ name: item?.name, value: false });
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                      options={item?.options}
                      onChange={(event, newValue, reason) => {
                        console.log("newVAlue=---------->", newValue);

                        setListItem((prevState) =>
                          prevState.map(
                            (list) =>
                              list.chkid === item.chkid
                                ? {
                                    ...list,
                                    value:
                                      item.type ===
                                      CHECKLIST_TYPE?.MULTI_SELECT_DROP_DOWN
                                        ? [...newValue]
                                        : newValue,
                                  } // Update value for the matched item
                                : list // Return other items as is
                          )
                        );

                        console.log("listItem=---->", listItem);
                      }}
                      renderOption={(props, option) => (
                        <Box style={{ fontSize: 13 }} {...props}>
                          {option}
                        </Box>
                      )}
                      value={
                        item?.value
                          ? item.type === CHECKLIST_TYPE?.MULTI_SELECT_DROP_DOWN
                            ? typeof item?.value === "string"
                              ? JSON.parse(item?.value)
                              : item?.value
                            : item?.value
                          : item.type === CHECKLIST_TYPE?.MULTI_SELECT_DROP_DOWN
                          ? []
                          : ""
                      }
                      getOptionLabel={(option) => {
                        if (option) {
                          return option;
                        } else {
                          return t("Please Select");
                        }
                      }}
                      // variant={variant}
                    />
                  )}
                </div>
              ))}
              <div className="d_flex justify_content_end marginT10">
                <Button onClick={handleSubmit} className="primary_button_small">
                  {t("Update")}
                </Button>
              </div>
            </>
          )}

          {/* {selectedStatus?.checklist?.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === item?.id}
              onChange={handleAccordion(item)}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                background: "rgba(55, 144, 107, 0.08)",
                border: "none",
                boxShadow: "none",
                marginBottom: "10px",
                ":before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: "#189d3d",
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="w_100 d_flex align_items_center justify_content_between">
                  <Typography
                    sx={{
                      color: "#189d3d",
                    }}
                    className="font_12_green_600"
                  >
                    {item?.name}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {loading && <>loading...</>}
                {!loading && (
                  <>
                    {listItem?.map((item, index) => (
                      <div key={index}>
                        <Checkbox
                          type="checkbox"
                          name="chkidvalue"
                          label={item?.name}
                          onChange={() => handleChecklist(item?.chkid)}
                          checked={item?.chkidvalue === 1}
                        />
                      </div>
                    ))}
                    <div className="d_flex justify_content_end marginT10">
                      <Button
                        onClick={handleSubmit}
                        className="primary_button_small"
                      >
                        Update
                      </Button>
                    </div>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          ))} */}
        </div>
      </ModalBody>
    </>
  );
};

export default CheckList;
