import { createSlice } from "@reduxjs/toolkit";
import Storage from "../../utils/Storage";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userDetails: null,
    userInfo: null,
    orgInfo: null,
    currentOrg: null,
    combinedPermissions: null,
    loading: false,
    error: null,
  },
  reducers: {
    getUserRegister(state) {
      state.loading = true;
      return state;
    },
    getUserLogin(state) {
      state.loading = true;
      return state;
    },

    getSunlightLogin(state) {
      state.loading = true;
      return state;
    },

    getUserLogout(state) {
      state.loading = true;
      return state;
    },
    getSignInWithGoogle(state) {
      state.loading = true;

      return state;
    },
    getSignInWithFacebook(state) {
      state.loading = true;

      return state;
    },
    getUser(state) {
      state.loading = true;
      return state;
    },
    getUserResetPassword(state) {
      state.loading = true;
      return state;
    },
    switchOrg: (state, action) => {
      // Storage.delete("permissions");
      state.loading = true;
      Storage.delete("permissions");

      const userInfo = JSON.parse(sessionStorage.getItem("user"));
      Storage.write(`org${userInfo?.id}`, action.payload);

      state.currentOrg = action.payload;
      state.loading = false;
    },

    setCurrentOrg: (state, action) => {
      // Storage.delete("permissions");
      state.loading = true;
      Storage.delete("permissions");

      const userInfo = JSON.parse(sessionStorage.getItem("user"));

      const tempOrg = Storage.read(`org${userInfo?.id}`) ?? null;
      Storage.write(`org${userInfo?.id}`, tempOrg ?? action.payload);
      state.currentOrg = tempOrg ?? action.payload;
      state.loading = false;
    },

    updateCurrentOrg: (state, action) => {
      // Storage.delete("permissions");
      state.loading = true;

      const userInfo = JSON.parse(sessionStorage.getItem("user"));

      Storage.write(`org${userInfo?.id}`, action.payload);

      state.currentOrg = action.payload;
      state.loading = false;
    },

    setCombinedPermission: (state, action) => {
      Storage.write("permissions", action.payload);
      const tempPermissions = Storage.read(`permissions`) ?? null;
      state.combinedPermissions = tempPermissions ?? action?.payload;
      state.loading = false;
    },

    setUser: (state, action) => {
      state.userInfo = action.payload;
      state.orgInfo = action.payload.organizations;
      state.loading = false;
    },
    setOrgInfo: (state, action) => {
      state.orgInfo = action.payload;
      state.loading = false;
    },
    setUserFailed: (state, action) => {
      state.loading = false;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.loading = false;
      state.error = null;
    },

    authSuccess: (state, action) => {
      state.userDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    logoutSuccess: (state, action) => {
      state.userDetails = null;
      state.currentOrg = null;
      state.loading = false;
      state.error = null;
    },
    passwordResetSuccess: (state, action) => {
      // state.userDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    authFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  getUserRegister,
  getUserLogin,
  getSunlightLogin,
  getUserLogout,
  getSignInWithGoogle,
  getSignInWithFacebook,
  getUser,
  switchOrg,
  setCurrentOrg,
  updateCurrentOrg,
  setCombinedPermission,
  setUser,
  setOrgInfo,
  setUserFailed,
  authSuccess,
  logoutSuccess,
  authFailure,
  setUserDetails,
  getUserResetPassword,
} = authSlice.actions;

export default authSlice.reducer;
