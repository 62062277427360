import { useState } from "react";
import SelectMenu from "../select/Select";
import CardTitle from "./CardTitle";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PlusIcon from "../../assets/images/analytics_plus.svg";
import MinusIcon from "../../assets/images/analytics_minus.svg";
import ZoomIcon from "../../assets/images/analytics_zoom.svg";
import PickIcon from "../../assets/images/analytics_pick.svg";

import { IconButton } from "@mui/material";

const RevenueAnalytics = ({ data }) => {
  const [selected, setSelected] = useState("all");

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const DataFormater = (number) => {
    return "$" + number.toString();
  };

  // const data = [
  //   {
  //     name: "Jan",
  //     profit: 10,
  //     sales: 50,
  //     amount: 10,
  //   },
  //   {
  //     name: "Feb",
  //     profit: 45,
  //     sales: 10,
  //     amount: 100,
  //   },
  //   {
  //     name: "Mar",
  //     profit: 10,
  //     sales: 10,
  //     amount: 10,
  //   },
  //   {
  //     name: "Apr",
  //     profit: 10,
  //     sales: 40,
  //     amount: 80,
  //   },
  //   {
  //     name: "May",
  //     profit: 10,
  //     sales: 10,
  //     amount: 10,
  //   },
  //   {
  //     name: "Jun",
  //     profit: 50,
  //     sales: 50,
  //     amount: 50,
  //   },
  //   {
  //     name: "Jul",
  //     profit: 50,
  //     sales: 50,
  //     amount: 50,
  //   },
  //   {
  //     name: "Aug",
  //     profit: 50,
  //     sales: 50,
  //     amount: 50,
  //   },
  //   {
  //     name: "Sep",
  //     profit: 50,
  //     sales: 50,
  //     amount: 50,
  //   },
  //   {
  //     name: "Oct",
  //     profit: 50,
  //     sales: 50,
  //     amount: 50,
  //   },
  //   {
  //     name: "Nov",
  //     profit: 50,
  //     sales: 50,
  //     amount: 50,
  //   },
  //   {
  //     name: "Dec",
  //     profit: 50,
  //     sales: 50,
  //     amount: 50,
  //   },
  // ];

  return (
    <div className="card_container">
      <CardTitle title="Revenue Analytics"></CardTitle>
      <div className="card_content">
        <div className="multi_chart" style={{ width: "100%", height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={400}
              data={data}
              // margin={{
              //   top: 20,
              //   right: 20,
              //   bottom: 20,
              //   left: 20,
              // }}
            >
              {/* Define gradient */}
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
                  <stop offset="0%" stopColor="rgba(91, 196, 255, 1)" />
                  <stop offset="100%" stopColor="rgba(255, 91, 239, 1)" />
                </linearGradient>
              </defs>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="period" />
              <YAxis tickFormatter={DataFormater} dataKey="system_cost" />
              <Tooltip />
              <Legend />
              {/* <Area
                type="monotone"
                dataKey="amount"
                fill="#F8F8F9"
                stroke="#F8F8F9"
              /> */}
              <Line
                type="monotone"
                dataKey="system_cost"
                stroke={"url(#colorGradient)"}
                // strokeDasharray="8 8"
                strokeWidth={3}
                dot={{ r: 0, strokeWidth: 0 }}
              />
              {/* <Line
                type="monotone"
                dataKey="profit"
                stroke="#189D3D"
                strokeWidth={3}
              /> */}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default RevenueAnalytics;
